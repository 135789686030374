import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// COMPONENTS
import Total from '../components/Total';
import DateSelection from '../components/DateSelection';
import Table2 from '../components/Table2';
import TrainingProgTable from '../components/TrainingProgTable';
// PROCESSES
import { procTraineeUnityData } from '../processes/fetchUserDataRtkQ';

// REDUX
import { setCurrentPage } from '../redux/app.slice';
import { useGetChaptersQuery, useGetUserDataQuery } from '../apis/rtkData.api';
// API
import { fetchChapterDataApi, getChapterGroupApi } from '../apis/data.api';

const TraineeDash = () => {
  // console.log('TraineeDash RENDERING');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userData, setUserData] = useState({});
  const [loadTest, setLoadTest] = useState(false); // eslint-disable-line
  const [startDate, setStartDate] = useState(new Date('07/01/23'));
  const [endDate, setEndDate] = useState(new Date());
  const [table2bData, setTable2bData] = useState([]);
  const [topNegTableData, setTopNegTableData] = useState([]);
  const [trainingProgData, setTrainingProgData] = useState([]);
  const [duration, setDuration] = useState(0);
  const [moduleScores, setModuleScores] = useState({});
  const [moduleCount, setModuleCount] = useState(0); // eslint-disable-line
  const [chapters, setChapters] = useState([]);
  const [ chapterGroup, setChapterGroup ] = useState({});
  const [negotiationCount, setNegotiationCount] = useState(0);
  const [negotiationTime, setNegotiationTime] = useState(0);
  const { loginStatus } = useSelector((state) => state.user, shallowEqual); // eslint-disable-line
  const { reactVerbosity } = useSelector((state) => state.app); // eslint-disable-line
  const { privacy } = useSelector((state) => state.app, shallowEqual); // eslint-disable-line
  const { user } = useSelector((state) => state.user, shallowEqual); // eslint-disable-line

  const selection = {
    startDate,
    endDate
  };
  /* eslint-disable */
  const {           
    data: chData,
    error,
    isLoading: isLoadingChData,
    isSuccess,
    isError,
    isFetching
  } = useGetChaptersQuery({
    authToken: user.idToken,
    user,
    selection
  }); 
  const {
    data: usrData,
    error: usrErr,
    isLoading: isLoadingUsrData
  } = useGetUserDataQuery({
    authToken: user.idToken,
    user,
    selection
  }); 
  /* eslint-enable */

  useEffect(() => {
    dispatch(setCurrentPage('traineeDash'));
  }, []);

  useEffect(() => {
    getChapterGroup();
  }, [user]);

  const getChapterGroup = async () => {
    const chGrp = await getChapterGroupApi(user.idToken, 'default');
    // console.log('--------------> LeaderDash chGrp:', chGrp)
    // if (chGrp.err) {
    //   setRevalidate((revalidate) => revalidate+1);
    // }
    setChapterGroup(chGrp);
  }

  useEffect(() => {
    window.addEventListener('popstate', (e) => {
      navigate('/', { replace: false });
    });
    return async () => {};
  }, []); // eslint-disable-line

  useEffect(() => {
    if (Object.keys(userData).length > 0) {
      // console.log('+++++> USER DATA:', userData)
      buildDataForTopNegTable();
      buildDataForTable2b();
      buildDataForTrainingProgressTable();
    }
  }, [userData && userData.sessionCount]);

  useEffect(() => {
    if (!isLoadingUsrData) {
      fetchUserDataProcess();
    }
  }, [isLoadingUsrData, usrData]); // eslint-disable-line

  const fetchUserDataProcess = async () => {
    const chapterData = await fetchChapterDataApi(user.idToken, user);
    const { userDataObj } = await procTraineeUnityData(usrData);

    reactVerbosity === 2 &&
      console.log('=======> fetchTraineeUnityData:', userDataObj);
    setDuration(userDataObj.duration);
    setNegotiationCount(userDataObj.negotiations);
    setNegotiationTime(userDataObj.time);
    setModuleCount(userDataObj.modules);
    setChapters(chapterData.data);
    setModuleScores(userDataObj.moduleScoreObj);
    setUserData(userDataObj);
  };

  const buildDataForTrainingProgressTable = async () => {
    // console.log('+++++> USER DATA:', userData)
    const lessons = {};
    const quizzes = {};
    const selfAssessments = {};

    const tableData =
      userData.sessions &&
      userData.sessions.map((s, index) => {
        // console.log("==========[> index, s:", index, s)
        return s.unitysessionmodules.map((m, i) => {  // eslint-disable-line
          // console.log('==========[> i, m:', i,  m)
          if (m.moduleType === 'lesson' && m.userScore !== -1 && m.userScore !== 0) {
            let date = new Date(s.sessionStartTime).toISOString().split('T')[0];
            const dateArr = date.split('-');
            date = dateArr[1] + '-' + dateArr[2] + '-' + dateArr[0];

            if (!lessons[s.user.name]) lessons[s.user.name] = {};
            if (!lessons[s.user.name][m.moduleName]) { lessons[s.user.name][m.moduleName] = {}; }
            lessons[s.user.name][m.moduleName] = {
              // moduleType: m.moduleType,
              moduleType: 'Lesson',
              module: m.moduleName,
              user: s.user.name,
              userScore: m.userScore,
              complete: m.userScore === -1 ? 'X' : '\u2713',
              date
            };
          }
          if (m.moduleType === 'quiz' && m.userScore !== -1 && m.userScore !== 0) {
            let date = new Date(s.sessionStartTime).toISOString().split('T')[0];
            const dateArr = date.split('-');
            date = dateArr[1] + '-' + dateArr[2] + '-' + dateArr[0];

            if (!quizzes[s.user.name]) quizzes[s.user.name] = {};
            if (!quizzes[s.user.name][m.moduleName]) { quizzes[s.user.name][m.moduleName] = {}; }
            quizzes[s.user.name][m.moduleName] = {
              // moduleType: m.moduleType,
              moduleType: 'Quiz',
              module: m.moduleName,
              user: s.user.name,
              userScore: m.userScore,
              complete: m.userScore === -1 ? 'X' : '\u2713',
              date
            };
          }

          if (m.moduleType === 'selfassessment' && m.userScore !== -1 && m.userScore !== 0) {
            let date = new Date(s.sessionStartTime).toISOString().split('T')[0];
            const dateArr = date.split('-');
            date = dateArr[1] + '-' + dateArr[2] + '-' + dateArr[0];

            if (!selfAssessments[s.user.name]) selfAssessments[s.user.name] = {};
            if (!selfAssessments[s.user.name][m.moduleName]) { selfAssessments[s.user.name][m.moduleName] = {}; }
            selfAssessments[s.user.name][m.moduleName] = {
              moduleType: 'Self Assessment',
              module: m.moduleName,
              user: s.user.name,
              userScore: m.userScore,
              complete: m.userScore === -1 ? 'X' : '\u2713',
              date
            };
            
          }
          if (Object.keys(lessons).length === 0) {
            lessons[s.user.name] = {};
          }
          if (Object.keys(quizzes).length === 0) {
            quizzes[s.user.name] = {};
          }
          if (Object.keys(selfAssessments).length === 0) {
            selfAssessments[s.user.name] = {};
          }
          
          // console.log('------> lessons:', lessons)
          // console.log('------> quizzes:', quizzes)
          // console.log('------> selfAssessments:', selfAssessments)

          if (m.moduleType !== 'negotiation') {
            let date = new Date(s.sessionStartTime).toISOString().split('T')[0];
            const dateArr = date.split('-');
            date = dateArr[1] + '-' + dateArr[2] + '-' + dateArr[0];
            return {
              moduleType: m.moduleType,
              module: m.moduleName,
              user: s.user.name,
              userScore: m.userScore,
              complete: m.userScore === -1 ? 'X' : '\u2713',
              date
            };
          }
        });
      });
    const arrFiltered = await filterNull(tableData);
    const flattened = arrFiltered.flat();
    const flattenedFiltered = flattened.filter((elements) => {
      return elements !== null && elements !== undefined;
    });

    // console.log('=============> buildDataForTrainingProgressTable flattenedFiltered DATA:', flattenedFiltered);
    
    reactVerbosity === 2 && console.log('==========> chapters:', chapters);
    // console.log('==========> chapters:', chapters);
    let chapterLessons = [];
    let chapterQuizzes = [];
    let chapterSelfAssessments = [];

    chapters && chapters.forEach((c) => {

      // console.log("====> c:", c)

        c.lessons.forEach((l) => {
          chapterLessons = [
            ...chapterLessons,
            {
              chapter: c.title,
              chapterId: c._id,
              chapterIndex: c.chapterIndex,
              number: l.moduleIndex,
              moduleType: 'Lesson',
              module: l.moduleName,
              userScore: -1,
              complete: 'X',
              date: ''
            }
          ];
        });

        c.quizzes.forEach((q) => {
          // console.log(`c: ${c.title}, q:`, q)
          chapterQuizzes = [
            ...chapterQuizzes,
            {
              chapter: c.title,
              chapterId: c._id,
              chapterIndex: c.chapterIndex,
              number: q.moduleIndex,
              moduleType: 'Quiz',
              module: q.moduleName,
              userScore: -1,
              complete: 'X',
              date: ''
            }
          ];
        });

        c.selfassessments && c.selfassessments.forEach((sa) => {
          // console.log('====> sa:', sa, chapterSelfAssessments)
          chapterSelfAssessments = [
            ...chapterSelfAssessments,
            {
              chapter: c.title,
              chapterIndex: c.chapterIndex,
              chapterId: c._id,
              number: sa.moduleIndex,
              moduleType: 'Self Assessment',
              module: sa.moduleName,
              userScore: -1,
              complete: 'X',
              date: ''
            }
          ];
        });

      });
    
    // console.log("====> chapterLessons (291):", chapterLessons)
    // console.log("====> chapterQuizzes (292):", chapterQuizzes)  // missing chapter-quizzes
    // console.log("====> chapterSelfAssessments (293):", chapterSelfAssessments)
    
    const rebuiltTableData = [];
    const lessonKeys = Object.keys(lessons);
    lessonKeys.forEach((k) => {
      chapterLessons.forEach((l) => {
        l.user = k;
        if (!lessons[k][l.module]) {
          lessons[k][l.module] = { ...l };
        } else {
          lessons[k][l.module].chapter = l.chapter;
          lessons[k][l.module].number = l.moduleIndex;
        }
        rebuiltTableData.push(lessons[k][l.module]);
      });
    });
    // console.log("----> lessons:", lessons)
    const quizKeys = Object.keys(quizzes);
    quizKeys.forEach((k) => {
      chapterQuizzes.forEach((q) => {
        q.user = k;
        if (!quizzes[k][q.module]) {
          quizzes[k][q.module] = { ...q };
        } else {
          quizzes[k][q.module].chapter = q.chapter;
          quizzes[k][q.module].number = q.moduleIndex;
        }
        rebuiltTableData.push(quizzes[k][q.module]);
      });
    });
    // console.log("----> quizzes:", quizzes)
    const selfAssessmentKeys = Object.keys(selfAssessments);
    selfAssessmentKeys.forEach((k) => {
      chapterSelfAssessments.forEach((sa) => {
        sa.user = k;
        // console.log("====> sa.user, sa:", sa.user, sa)
        // console.log("======> selfAssessments[k]:", selfAssessments[k])
        if (!selfAssessments[k][sa.module]) {
          selfAssessments[k][sa.module] = { ...sa };
        } else {
          selfAssessments[k][sa.module].chapter = sa.chapter;
          selfAssessments[k][sa.module].number = sa.moduleIndex;
        }
        rebuiltTableData.push(selfAssessments[k][sa.module]);
      });
    });

    reactVerbosity === 2 &&
      console.log('===> rebuiltTableData:', rebuiltTableData);

    let chapterGroupObj = {};
    rebuiltTableData.forEach((m) => {
        if (!chapterGroupObj[m.chapterIndex]) chapterGroupObj[m.chapterIndex] = [];
        chapterGroupObj[m.chapterIndex] = [...chapterGroupObj[m.chapterIndex], m];
    })
    // console.log('chapterGroupObj:', chapterGroupObj)
    const cgKeys = Object.keys(chapterGroupObj);
    let concatenatedTableData = [];
    cgKeys.map((k) => {
      return concatenatedTableData = [...concatenatedTableData, chapterGroupObj[k]]
    })

    setTrainingProgData(concatenatedTableData.flat());
    return concatenatedTableData.flat();
  };

  const buildDataForTopNegTable = async () => {
    // console.log('+++++> USER DATA:', userData)
    // TOP NEGOTIATIONS
    const validModuleTypes = Object.keys(userData.totalModulesByType);
    const tableData =
      userData.sessions &&
      userData.sessions.map((s, index) => {
        return s.unitysessionmodules.map((m, i) => {
          if (
            m.moduleType === 'negotiation' &&
            s.sessionLength > 0 &&
            m.userScore > 0 &&
            validModuleTypes.includes(m.moduleName)
          ) {
            // console.log('==========[> i, m:', i,  m, m.moduleName, userData.totalModulesByType)
            // console.log("TRAINEE =========> moduleScoreObj:", userData.moduleScoreObj)
            let date = new Date(s.sessionStartTime).toISOString().split('T')[0];
            const dateArr = date.split('-');
            date = dateArr[1] + '-' + dateArr[2] + '-' + dateArr[0];
            const moduleCountByType = userData.totalModulesByType[m.moduleName]
              ? userData.totalModulesByType[m.moduleName].length
              : 0;
            // console.log('TRAINEE =====> TopNegTable moduleCountByType:', moduleCountByType)
            const maxUserScore = Math.max(...userData.moduleScoreObj[m.moduleName]);
            // console.log("TRAINEE ======> m.moduleName, maxUserScore:", m.moduleName, maxUserScore,userData.moduleScoreObj[m.moduleName])
            return {
              negotiation: m.moduleName,
              user: s.user.name,
              userScore: maxUserScore,
              maxScore: m.maxScore,
              moduleCountByType,
              date
            };
          } else return null;
        });
      });
    const arrFiltered = await filterNull(tableData);
    const flattened = arrFiltered.flat();
    const flattenedFiltered = flattened.filter((e) => !Array.isArray(e));
    // console.log('buildDataForTable2 tableData, flattened, flattenedFiltered DATA:', tableData, flattened, flattenedFiltered)
    const uniqueModules = Array.from(
      new Set(flattenedFiltered.map((a) => a.negotiation))
    ).map((negotiation) => {
      return flattenedFiltered.find((a) => a.negotiation === negotiation);
    });
    setTopNegTableData(uniqueModules);
    reactVerbosity === 2 &&
      console.log('buildDataForTopNegTable uniqueModules DATA:', uniqueModules);
    return uniqueModules;
  };

  const buildDataForTable2b = async () => {
    const tableData =
      userData.sessions &&
      userData.sessions.map((s, index) => {
        // console.log('=========[> data Table2b SESSION:', s)
        return s.unitysessionmodules.map((m, i) => {
          if (m.moduleType === 'negotiation') {
            let date = new Date(s.sessionStartTime).toISOString().split('T')[0];
            const dateArr = date.split('-');
            date = dateArr[1] + '-' + dateArr[2] + '-' + dateArr[0];
            const moduleCountByType = userData.totalModulesByType[m.moduleName]
              ? userData.totalModulesByType[m.moduleName].length
              : 0;
            // console.log('TRAINEE table2bData moduleCountByType:', moduleCountByType)

            return {
              negotiation: m.moduleName,
              user: s.user.name,
              userScore: m.userScore,
              maxScore: m.maxScore,
              moduleScores,
              moduleCountByType,
              date
            };
          } else return null;
        });
      });
    // console.log('buildDataForTable2 tableData DATA:', tableData);
    const arrFiltered = await filterNull(tableData);
    // console.log('buildDataForTable2 arrFiltered DATA:', arrFiltered);
    let flattened = arrFiltered.flat();
    flattened = flattened.filter((e) => !Array.isArray(e));
    setTable2bData(flattened);
    // console.log('buildDataForTable2 flattened DATA:', flattened);
    reactVerbosity === 2 &&
      console.log('buildDataForTable2 flattened DATA:', flattened);
    return flattened;
  };

  const filterNull = (tableData) => {
    return tableData.map((e) => {
      return e.map((m) => {
        if (m !== null && m !== undefined) {
          return m;
        }
        return [];
      });
    });
  };

  const setDefaultDates = async (start, end) => {
    await setStartDate(start);
    await setEndDate(end);
  };

  const handleStartDate = (date) => {
    setStartDate(date);
  };

  const handleEndDate = (date) => {
    setEndDate(date);
  };

  return (
    <div className="page-grid">
      <div className="trainee-page-container">
        <div className="trainee-page">
          <div className="trainee-page-grid">
            <div className="trainee-page-date-range-selector">
              {!loadTest && (
                <DateSelection
                  start={startDate}
                  end={endDate}
                  setDefaultDates={setDefaultDates}
                  handleStartDate={handleStartDate}
                  handleEndDate={handleEndDate}
                />
              )}
            </div>
            <div className="trainee-page-sect-2">
              <div className="trainee-page-total-sessions">
                {!loadTest && (
                  <Total
                    label="Sessions"
                    value={userData.sessionCount}
                    width={70}
                    height={70}
                    borderRadius={30}
                  />
                )}
              </div>
              <div className="trainee-page-total-time">
                {!loadTest && (
                  <Total
                    label="Session Time"
                    units="min"
                    value={duration}
                    width={70}
                    height={70}
                    borderRadius={30}
                  />
                )}
              </div>
              <div className="trainee-page-total-negotiations">
                {!loadTest && (
                  <Total
                    label="Negotiations"
                    units=""
                    value={negotiationCount}
                    width={70}
                    height={70}
                    borderRadius={30}
                  />
                )}
              </div>
              <div className="trainee-page-total-negotiation-time">
                {!loadTest && (
                  <Total
                    label=" Negotiation"
                    units="min"
                    value={(negotiationTime / 1000 / 60).toFixed(0)}
                    width={70}
                    height={70}
                    borderRadius={30}
                  />
                )}
              </div>
              {false && (
                <div className="trainee-page-active-users">
                  {!loadTest && (
                    <Total
                      label="TBD"
                      units=""
                      value={'N/A'}
                      width={70}
                      height={70}
                      borderRadius={30}
                    />
                  )}
                </div>
              )}
            </div>
            <div className="trainee-page-sect-3">
              <div className="trainee-page-progress-grid">
                <div className="trainee-page-progress-header">
                  <div className="trainee-page-progress-label">
                    Your Training Progress
                  </div>
                </div>
                <div className="trainee-page-progress-table">
                  <TrainingProgTable tableData={trainingProgData} />
                </div>
              </div>
            </div>
            {false && (
              <div className="trainee-page-sect-4">
                <div className="trainee-sect-4-grid"></div>
              </div>
            )}
            <div className="trainee-page-sect-5">
              {true && (
                <div className="trainee-page-table-1-grid">
                  <div className="trainee-page-table-1-header">
                    <div className="trainee-page-table1-label">
                      Top Negotiations
                    </div>
                  </div>
                  {topNegTableData.length > 0 && (
                    <div className="trainee-page-table-1">
                      <Table2 data={topNegTableData} />
                    </div>
                  )}
                </div>
              )}
            </div>
            {false && (
              <div className="trainee-page-sect-6">
                {false && !loadTest && (
                  <Total
                    label="Label"
                    value={''}
                    width={100}
                    borderRadius={30}
                  />
                )}
              </div>
            )}
            <div className="trainee-page-sect-7">
              {true && (
                <div className="trainee-page-table-1-grid">
                  <div className="trainee-page-table-1-header">
                    <div className="trainee-page-table1-label">
                      All Negotiations
                    </div>
                  </div>
                  {table2bData.length > 0 && (
                    <div className="trainee-page-table-1">
                      <Table2 data={table2bData} />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TraineeDash;
