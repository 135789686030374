import axios from 'axios';

export const fetchOrganizationsApi = async (authtoken) => {
  const axiosInstance = axios.create({
    withCredentials: true
  });
  return await axiosInstance.get(
    process.env.REACT_APP_API + '/organizations/get',
    {
      headers: {
        authtoken
      }
    }
  );
};
export const fetchOrganizationId = async (name) => {
  const axiosInstance = axios.create({
    withCredentials: true
  });
  return await axiosInstance.post(
    process.env.REACT_APP_API + '/organization/get/id',
    { name }
  );
};
export const addOrganizationToDB = async (authtoken, organization) => {
  // console.log('========> addOrganizationToDB:', organization);
  const config = {
    headers: {
      authtoken
    }
  };
  const promise = await new Promise((resolve, reject) => {
    const axiosInstance = axios.create({
      withCredentials: true
    });
    const response = axiosInstance.post(
      process.env.REACT_APP_API + '/create/organization',
      { organization },
      config
    );
    resolve(response);
  });
  return promise;
};

export const fetchUserCacheApi = async (authtoken) => {
  const axiosInstance = axios.create({
    withCredentials: true
  });
  return await axiosInstance.get(
    process.env.REACT_APP_API + '/user/cache/get',
    {
      headers: {
        authtoken
      }
    }
  );
};

export const checkUserCacheApi = async (email) => {
  const axiosInstance = axios.create({
    withCredentials: true
  });
  const response = await axiosInstance.post(
    process.env.REACT_APP_API + '/user/cache/check',
    // { email, organization }
    { email }
  );
  return response;
};

export const addUserToCache = async (authtoken, user) => {
  // console.log('====> addUserToCache:', user);
  const config = {
    headers: {
      authtoken
    }
  };
  const promise = await new Promise((resolve, reject) => {
    const axiosInstance = axios.create({
      withCredentials: true
    });
    const response = axiosInstance.post(
      process.env.REACT_APP_API + '/user/cache/add',
      { user },
      config
    );
    resolve(response);
  });
  return promise;
};

export const verifyUser = async () => {
  // const startTime = performance.now();
  // console.log("====> verification.data:", verification.data)

  const promise = await new Promise((resolve, reject) => {
    const axiosInstance = axios.create({
      withCredentials: true
    });
    const response = axiosInstance.get(
      process.env.REACT_APP_API + '/user/verify'
    );
    // console.log('-----------> /user/verify RESPONSE:', response)
    resolve(response);
  });
  // const endTime = performance.now()
  // console.log(`ttttttttttttt ---->> admin.api verifyUser took ${endTime - startTime} milliseconds`)

  return promise;
};

export const updateSession = async (authToken) => {
  // console.log('======>> updateSession:', authToken)
  const promise = await new Promise((resolve, reject) => {
    const axiosInstance = axios.create({
      withCredentials: true
    });
    const response = axiosInstance.post(
      process.env.REACT_APP_API + '/session/update',
      { authToken }
    );
    resolve(response);
  });
  return promise;
};
