import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  registerables as registerablesJS
} from 'chart.js';
import { Bar, Chart } from "react-chartjs-2"; // eslint-disable-line
ChartJS.register(...registerablesJS);

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top'
    },
    title: {
      display: true,
      text: 'Session Data'
    }
  }
};

const colors = [
  '#994ccb',
  '#36d1ff',
  '#7ca982',
  '#EF8A17',
  '#546A7B',
  '#2A1E5C',
  '#6096BA'
];

const LeftComboGraph = ({
  sessionsPerDate,
  sessionTimePerDate,
  reactVerbosity
}) => {
  // console.log('LeftComboGraph RENDERING');
  reactVerbosity === 2 &&
    console.log(
      '====> LeftComboGraph DATA:',
      sessionsPerDate,
      sessionTimePerDate
    );
  const labels = Object.keys(sessionsPerDate[0]);
  labels.sort((a, b) => {
    const aDate = new Date(a);
    const bDate = new Date(b);
    // console.log('a,b', aDate, bDate)
    return aDate - bDate;
  });
  const newSessionTimePerDate = labels.map((l) => {
    return sessionTimePerDate[l] / 60;
  });
  // console.log('=====> LABELS:', labels, sessionsPerDate, newSessionTimePerDate)
  const datasets = [
    {
      type: 'line',
      label: 'Session Times (hr)',
      data: newSessionTimePerDate,
      borderColor: colors[1],
      backgroundColor: colors[0],
      fill: false
    },
    {
      type: 'bar',
      label: 'Sessions by Date',
      data: sessionsPerDate[0],
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: colors[0],
      fill: true
    }
  ];
  // console.log('DATASETS:', datasets)
  const dataObj = {
    labels,
    datasets
  };

  // console.log('========> LeftComboGraph Data:', dataObj)
  // return (
  //   <Bar options={options} data={dataObj} />
  // )
  return <Chart type="bar" data={dataObj} />;
};

// export default React.memo(LeftComboGraph);
export default React.memo(LeftComboGraph);

// console.log('VALUES:', values)
// const datasets = sessionsPerDate.map((set, index) => {
//   return {
//     label: `Dataset ${index}`,
//     data: sessionsPerDate[index],
//     borderColor: 'rgb(255, 99, 132)',
//     backgroundColor: colors[index],
//   }
// })

// const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

// export const data = {
//   labels,
//   datasets: [
//     {
//       label: 'Dataset 1',
//       data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
//       backgroundColor: 'rgba(255, 99, 132, 0.5)',
//     },
//     {
//       label: 'Dataset 2',
//       data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
//       backgroundColor: 'rgba(53, 162, 235, 0.5)',
//     },
//   ],
// };
