import React, { useMemo } from 'react';
// import { Box, Stack } from '@mantine/core';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
// import { data } from './trainingProgData';
// import { data } from './makeData2';

const NegNameTraineeScoreTable = ({ data, reactVerbosity }) => {
  // console.log('NegNameTraineeScoreTable RENDERING', data);
  data.forEach((s, i) => {
    // console.log("s.userScore:",s.userScore)
    let userScoreStr = '';
    if (typeof s.userScore === 'string' && s.userScore.includes('/')) {
      const userScoreArr = s.userScore && s.userScore.split('/');
      // userScoreStr = `${userScoreArr[0]}/${s.maxScore}`;
      userScoreStr = ((parseInt(userScoreArr[0])/s.maxScore) * 100).toFixed();
      userScoreStr = `${userScoreStr}%`;
    } else {
      // userScoreStr = `${s.userScore}/${s.maxScore}`;
      userScoreStr = ((s.userScore/s.maxScore) * 100).toFixed();
      userScoreStr = `${userScoreStr}%`;
    }
    data[i].userScoreNew = userScoreStr;
  });

  reactVerbosity >= 2 && console.log('###################> NegNameTraineeScoreTable DATA:', data);
  const columns = useMemo(() => [
    {
      header: 'Negotiation',
      accessorKey: 'negotiation',
      enableGrouping: true
    },
    {
      header: 'Trainee',
      accessorKey: 'user'
    },
    {
      header: 'Score',
      accessorKey: 'userScoreNew'
    },
    {
      accessorKey: 'date',
      header: 'Date'
    }
    // {
    //   header: 'Complete',
    //   accessorKey: 'complete',
    //   Footer: () => (
    //     <Stack style={{ marginRight: "150px", display: "flex", justifyContent: "left", alignItems: "center" }}>
    //       Progress:
    //       <Box color="orange">{completed}/{count}</Box>
    //     </Stack>
    //   ),
    // },
    // {
    //   header: 'Max Score',
    //   accessorKey: 'maxScore',
    // },
  ]);

  const table = useMantineReactTable({
    columns,
    data,
    enableColumnResizing: true,
    enableGrouping: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    initialState: {
      density: 'xs',
      expanded: true,
      grouping: ['negotiation'],
      pagination: { pageIndex: 0, pageSize: 20 },
      sorting: [{ id: 'negotiation', desc: false }]
    },
    // mantineToolbarAlertBannerBadgeProps: { color: 'blue', variant: 'outline' },
    mantineTableContainerProps: { sx: { height: '80%' } }
  });

  return <MantineReactTable table={table} />;
};

export default React.memo(NegNameTraineeScoreTable);
