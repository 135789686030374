import axios from 'axios';

export const deleteUserSessions = async (authtoken, email) => {
  const axiosInstance = axios.create({
    withCredentials: true
  });
  const config = {
    headers: {
      authtoken
    }
  };
  return await axiosInstance.post(
    process.env.REACT_APP_API + '/del/user/sessions',
    { email },
    config
  );
};
export const createBackupOfDB = async (authtoken) => {
  // console.log('api call createBackupOfDB... ');
  const axiosInstance = axios.create({
    withCredentials: true
  });
  const config = {
    headers: {
      authtoken
    }
  };
  return await axiosInstance.post(
    process.env.REACT_APP_API + '/create/backup/db',
    {},
    config
  );
};

export const fetchChapterDataApi = async (authtoken, user) => {
  const axiosInstance = axios.create({
    withCredentials: true
  });
  const config = {
    headers: {
      authtoken
    }
  };
  return await axiosInstance.post(
    process.env.REACT_APP_API + '/get/chapters',
    { 
      authtoken,
      user 
    },
    config
  );
};

export const createCustomChapterGroupApi = async (authtoken, cg) => {
  // console.log('---- createCustomChapterGroupApi');
  const axiosInstance = axios.create({
    withCredentials: true
  });
  const config = {
    headers: {
      authtoken
    }
  };
  return await axiosInstance.post(
    process.env.REACT_APP_API + '/update/chapter/group',
    { cg },
    config
  );
}

export const getChapterGroupApi = async (authtoken, name) => {
  // console.log('---- getChapterGroupApi', name);
  const axiosInstance = axios.create({
    withCredentials: true
  });
  const config = {
    headers: {
      authtoken
    }
  };
  return await axiosInstance.post(
    process.env.REACT_APP_API + '/get/chapter/group',
    { name },
    // config
  );
}
