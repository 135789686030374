import React, { useMemo } from 'react';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';

const Table2 = ({ data, reactVerbosity }) => {
  // console.log('Table2 RENDERING');
  data.forEach((s, i) => {
    // const userScoreStr = `${s.userScore}/${s.maxScore}`;
    // data[i].userScore = userScoreStr;
    let userScoreStr = '';
    if (typeof s.userScore === 'string' && s.userScore.includes('/')) {
      const userScoreArr = s.userScore && s.userScore.split('/');
      // userScoreStr = `${userScoreArr[0]}/${s.maxScore}`;
      userScoreStr = ((parseInt(userScoreArr[0])/s.maxScore) * 100).toFixed();
      userScoreStr = `${userScoreStr}%`;
    } else {
      // userScoreStr = `${s.userScore}/${s.maxScore}`;
      userScoreStr = ((s.userScore/s.maxScore) * 100).toFixed();
      userScoreStr = `${userScoreStr}%`;
    }
    data[i].userScoreNew = userScoreStr;
  });
  reactVerbosity === 2 &&
    console.log('###################> TABLE2 DATA:', data);
  const columns = useMemo(() => [
    {
      accessorKey: 'negotiation', // access nested data with dot notation
      header: 'Negotiation'
    },
    // {
    //   accessorKey: 'user', // access nested data with dot notation
    //   header: 'Trainee'
    // },
    {
      accessorKey: 'userScoreNew',
      header: 'Score'
    },
    // {
    //   accessorKey: 'maxScore',
    //   header: 'MaxScore',
    // },
    {
      accessorKey: 'moduleCountByType',
      header: 'Attempts'
    },
    {
      accessorKey: 'date',
      header: 'Date'
    }
    // {
    //   accessorKey: 'moduleCount',
    //   header: 'Attempts',
    // }
  ]);
  const table = useMantineReactTable({
    columns,
    data,
    enableColumnResizing: true,
    enableGrouping: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    initialState: {
      density: 'xs',
      expanded: true,
      // grouping: ['negotiation'],
      pagination: { pageIndex: 0, pageSize: 20 }
      // sorting: [{ id: 'negotiation', desc: false }],
    },
    mantineTableContainerProps: { sx: { height: '80%' } }
  });

  return <MantineReactTable table={table} />;
};

export default React.memo(Table2);

// mantineTableBodyRowProps={{
//   sx: {
//     height: '10px'
//   }
// }} mantineTableBodyCellProps={{
//   sx: {
//     padding: '2px 16px'
//   }
// }}
// mantinePaperProps={{
//   shadow: 'xs'
// }}
