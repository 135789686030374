import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loginStatus: false,
  refreshCount: 0,
  user: {
    id: '',
    name: '',
    email: '',
    emailVerified: null,
    phone: '',
    organization: '',
    role: '',
    idToken: null
  }
};

export const appSlice = createSlice({
  name: 'updateUserState',
  initialState,
  reducers: {
    resetUser: (state, action) => {
      // console.log('!!!!RESETING USER!!!!!');
      state.user = {
        id: '',
        name: '',
        email: '',
        emailVerified: null,
        phone: '',
        organization: '',
        role: '',
        idToken: null
      };
      state.fbUser = undefined;
      // console.log('STATE AFTER RESTE:', state);
    },
    setFbUser: (state, action) => {
      state.fbUser = JSON.parse(action.payload);
    },
    setIdToken: (state, action) => {
      state.user.idToken = action.payload;
      // console.log('setIdToken:', action.payload)
    },
    setUser: (state, action) => {
      // console.log('===> REDUX setUser:', action.payload)
      // state.user = action.payload;
      const user = JSON.parse(action.payload);
      state.user.email = user.email;
      state.user.emailVerified = user.emailVerified;
      // console.log('setUser: ', user)
    },
    setLoginStatus: (state, action) => {
      state.loginStatus = action.payload;
    },
    setRrefreshCount: (state, action) => {
      state.refreshCount = state.refreshCount + 1;
      console.log('### state.refreshCount:', state.refreshCount)
    },
    setId: (state, action) => {
      state.user.id = action.payload;
    },
    setName: (state, action) => {
      state.user.name = action.payload;
    },
    setEmail: (state, action) => {
      state.user.email = action.payload;
    },
    setEmailVerified: (state, action) => {
      state.user.emailVerified = action.payload;
    },
    setPhone: (state, action) => {
      state.user.phone = action.payload;
    },
    setOrganization: (state, action) => {
      state.user.organization = action.payload;
    },
    setRole: (state, action) => {
      // console.log('SETTING ROLE:', action)
      state.user.role = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  resetUser,
  setFbUser,
  setUser,
  setName,
  setRole,
  setOrganization,
  setLoginStatus,
  setEmailVerified,
  setIdToken,
  setRrefreshCount
} = appSlice.actions;
// You must export the reducer as follows for it to be able to be read by the store.
export default appSlice.reducer;
