import axios from 'axios';

export const writeRegistrationErrorToS3Api = async (data) => {
    // console.log('api call writeRegistrationErrorToS3Api... ', data);
    const axiosInstance = axios.create({
      withCredentials: true
    });
    // const config = {
    //   headers: {
    //     authtoken
    //   }
    // };
    return await axiosInstance.post(
      process.env.REACT_APP_API + '/log/registration/error',
      { data }
    );
  };
export const writeRegistrationSuccessToS3Api = async (authtoken, data) => {
    // console.log('api call writeRegisterSuccessToS3Api... ', data);
    const axiosInstance = axios.create({
      withCredentials: true
    });
    const config = {
      headers: {
        authtoken
      }
    };
    return await axiosInstance.post(
      process.env.REACT_APP_API + '/log/registration/success',
      { data },
      config
    );
  }; 

export const writeLoginErrorToS3Api = async (data) => {
    // console.log('api call writeLoginErrorToS3Api... ', data);
    const axiosInstance = axios.create({
      withCredentials: true
    });
    // const config = {
    //   headers: {
    //     authtoken
    //   }
    // };
    return await axiosInstance.post(
      process.env.REACT_APP_API + '/log/login/error',
      { data }
    );
  };

export const writeLoginSuccessToS3Api = async (authtoken, data) => {
    // console.log('!!!!!!! api call writeLoginSuccessToS3Api... ', data);
    const axiosInstance = axios.create({
      withCredentials: true
    });
    const config = {
      headers: {
        authtoken
      }
    };
    return await axiosInstance.post(
      process.env.REACT_APP_API + '/log/login/success',
      { data },
      config
    );
  };   