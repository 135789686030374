
// ------
// ADMIN
// ------
export const procAdminUnityData = async (usrData, moduleCount) => {
  // console.log('==> ADMIN UNITY DATA:', usrData)
  let duration = 0;
  let negotiations = 0;
  let time = 0;
  const titles = [];
  const lengths = [];
  const lengthsObj = {};
  let modules = moduleCount;
  const names = [];
  const sessions = [];
  let sessionCount = 0;
  const sessionCountObj = {};
  const sessionCountPerOrgObj = {};
  const sessionsPerDate = {};
  const sessionTimePerDate = {};
  const negotiationTimePerDate = {};
  const negotiationsPerSession = [];
  const moduleScoreObj = {};
  const totalModulesByType = {};
  const sessionsPerName = {};
  const modulesPerName = {};
  let uniqueModuleNamesPerUser = {};
  let moduleCountPerName = {};
  let uniqueModulesPerName = [];
  const negotiationsPerUser = {};
  let sessonCount = 0;
  const sessionsLength = usrData.length;
  // console.log('(31) sessionsLength:', sessionsLength)
  // ITERATE over all SESSIONS
  usrData.forEach((s, index) => {
    sessonCount += 1;
    // console.log('(34) --> UNITY usrData sessonCount, s:',sessonCount, s)
    modules += s.unitysessionmodules.length;
    // DO NOT ADD IF MISSING DATA
    if (s.sessionLength && s.sessionLength > 0 && modules > 0 && s.unitysessionmodules.length > 0 && s.user) {
      // console.log('--------------> UNITY usrData index, s:',index, s)
      sessions.push(s);
      sessionCount += 1;
      duration += s.sessionLength;

      if (!sessionCountObj[s.user.name]) sessionCountObj[s.user.name] = 0;
      sessionCountObj[s.user.name] = sessionCountObj[s.user.name] + 1;

      if (!sessionCountPerOrgObj[s.organization.name]) { sessionCountPerOrgObj[s.organization.name] = 0; }
      sessionCountPerOrgObj[s.organization.name] = sessionCountPerOrgObj[s.organization.name] + 1;

      let date = new Date(s.sessionStartTime).toISOString().split('T')[0];
      const dateArr = date.split('-');
      date = dateArr[1] + '-' + dateArr[2] + '-' + dateArr[0];
      // console.log('=====> DATE:', index, date, s._id)
      if (!sessionsPerDate[date]) sessionsPerDate[date] = 0;
      sessionsPerDate[date] = sessionsPerDate[date] + 1;

      if (!sessionTimePerDate[date]) sessionTimePerDate[date] = 0;
      sessionTimePerDate[date] =
        sessionTimePerDate[date] + s.sessionLength / 1000 / 60;
      // console.log('----------------> sessionTimePerDate:', sessionTimePerDate);
      if (!sessionsPerName[s.user.name]) { 
        sessionsPerName[s.user.name] = [s];
        modulesPerName[s.user.name] = [s.unitysessionmodules];
        modulesPerName[s.user.name] = modulesPerName[s.user.name].flat();
        // console.log('---> does not exist s', s, sessionsPerName[s.user.name], modulesPerName[s.user.name])
      } else {
        sessionsPerName[s.user.name] = [...sessionsPerName[s.user.name], s];
        modulesPerName[s.user.name] = [...modulesPerName[s.user.name], s.unitysessionmodules];
        modulesPerName[s.user.name] = modulesPerName[s.user.name].flat();
        // console.log('---> exists s', s, sessionsPerName[s.user.name], modulesPerName[s.user.name])
      };

      // console.log('+++ (75) ADMIN DATA modulesPerName:', modulesPerName)
      uniqueModuleNamesPerUser[s.user.name] = uniqueModulesPerName;

    const names = Object.keys(modulesPerName);
    names.forEach((n, i) => {
      let arrUniqueMuduleIds = [];
        modulesPerName[n].forEach((m, i) => {
          // console.log('m._id:', m._id)
          // if (arrUniqueMuduleIds.indexOf(m._id) === -1) {
          //   arrUniqueMuduleIds.push(m._id);
          if (arrUniqueMuduleIds.indexOf(m.moduleName) === -1) {
            arrUniqueMuduleIds.push(m.moduleName);
            if (m.userScore !== -1) {
              if (!moduleCountPerName[n]) {
                moduleCountPerName[n] = 1;
              } else {
                moduleCountPerName[n] =  moduleCountPerName[n]+1;
              }
            }
          } else {
            // DUPLICATE
            // console.log('DUPLICATE', n, i)
          }
        })
        moduleCountPerName[n] = arrUniqueMuduleIds.length;
        // console.log('arrUniqueMuduleIds.length:', n, arrUniqueMuduleIds.length)
    })

    // console.log('(123) +++ ADMIN DATA moduleCountPerName:', moduleCountPerName)

    // ITERATE over MODULES per SESSION
    s.unitysessionmodules.forEach((m, i) => {
      // console.log('====> i, m:', m.moduleName , i, m)
      // --> Accumulate Negotiation Total Negotiation Time in mS
      // ONLY NEGOTIATION
      if (m.moduleType === 'negotiation' && m.userScore !== -1 && m.endTime - m.startTime > 0) {
        time += m.endTime - m.startTime;
        negotiations += 1;
        titles.push(m.moduleName);
        lengths.push(m.endTime - m.startTime);

        if (!negotiationsPerUser[s.user.name]) negotiationsPerUser[s.user.name] = [];
        negotiationsPerUser[s.user.name] = [...negotiationsPerUser[s.user.name], m];

        if (!negotiationTimePerDate[date]) negotiationTimePerDate[date] = 0;
          negotiationTimePerDate[date] = negotiationTimePerDate[date] + (m.endTime - m.startTime) / 1000 / 60;
          // console.log('----------------> negotiationTimePerDate:', negotiationTimePerDate);
          if (!negotiationsPerSession[index]) { negotiationsPerSession[index] = []; }
          negotiationsPerSession[index] = [...negotiationsPerSession[index], m];

          if (!totalModulesByType[m.moduleName]) { totalModulesByType[m.moduleName] = []; }
          totalModulesByType[m.moduleName] = [...totalModulesByType[m.moduleName], { s: index, m: i, type: m.moduleType, user: s.user.name }];

          if (!moduleScoreObj[m.moduleName]) moduleScoreObj[m.moduleName] = [];
          moduleScoreObj[m.moduleName] = [...moduleScoreObj[m.moduleName], m.userScore];
          // console.log("lengthsObj BEFORE:", s.user.name, " :", lengthsObj)
          if (!lengthsObj[s.user.name]) {
            lengthsObj[s.user.name] = 0;
            // console.log("lengthsObj CREATING:", s.user.name, " :", lengthsObj)
          }
          lengthsObj[s.user.name] = lengthsObj[s.user.name] + (m.endTime - m.startTime) / 1000 / 60;
        }
        // console.log("lengthsObj AFTER:", s.user.name, " :", lengthsObj)
        names.push(s.user.name);
      });
    }
  });

  // pack all prepared usrData into dataObj and return
  const userDataObj = {
    duration: (duration / 1000 / 60).toFixed(), // Total Time
    sessions, // buildDataForTable1, buildDataForTable3, buildDataForNegTable, buildDataForTopNegTable, <LeftComboGraph
    sessionCount,           // sensitivity for build data useEffect
    sessionCountObj,        // table1Data, buildDataForTable3
    sessionCountPerOrgObj,  // buildDataForTable1, buildDataForTable3
    sessionsPerDate,        // <LeftComboGraph
    sessionTimePerDate,     // <LeftComboGraph
    negotiationTimePerDate, // <RightComboGraph
    negotiationsPerSession, // buildDataForTable1, buildDataForTable3
    negotiations,           // setMaxModuleCount
    time,                   // setMaxModuleCount
    titles,                 // setMaxModuleCount
    lengths,                // setMaxModuleCount
    lengthsObj,             // buildDataForTable1, buildDataForTable3
    modules,                // setMaxModuleCount
    totalModulesByType,     // buildDataForTopNegTable
    moduleScoreObj,         // buildDataForTopNegTable
    sessionsPerName,
    modulesPerName,
    moduleCountPerName,
    negotiationsPerUser,
    uniqueModuleNamesPerUser
  };
  // console.log('========> fetchAdminUnityData SENDING:', userDataObj)
  // endTime = performance.now()
  // console.log(`ttttttttttttt ---->> fetchUserData process fetchAdminData took ${endTime - startTime} milliseconds`)

  return { userDataObj };
};

// ----------
// TEAM LEAD
// ----------
export const procLeadUnityData = async (usrData, moduleCount) => {
  // console.log('*** procLeadUnityData clled ***')
  let duration = 0;
  let negotiations = 0;
  let time = 0;
  const lengths = [];
  const lengthsObj = {};
  let modules = moduleCount;
  const names = [];
  const sessions = [];
  let sessionCount = 0;
  const sessionCountObj = {};
  const sessionCountPerOrgObj = {};
  const sessionsPerDate = {};
  const sessionsPerName = {};
  const modulesPerName = {};
  const uniqueModuleNamesPerUser = {};
  let moduleCountPerName = {};
  let uniqueModulesPerName = [];
  const negotiationsPerUser = {};
  const sessionTimePerDate = {};
  const negotiationTimePerDate = {};
  const negotiationsPerSession = [];

  // ITERATE over all SESSIONS
  usrData.forEach((s, index) => {
    // console.log(('LEADER DATA SESSION s:', index, s))
    modules += s.unitysessionmodules.length;

    if (s.sessionLength && s.sessionLength > 0 && modules > 0 && s.unitysessionmodules.length > 0 && s.user) {
      sessions.push(s);
      sessionCount += 1;
      duration += s.sessionLength;

      if (!sessionCountObj[s.user.name]) sessionCountObj[s.user.name] = 0;
      sessionCountObj[s.user.name] = sessionCountObj[s.user.name] + 1;

      if (!sessionCountPerOrgObj[s.organization.name]) { sessionCountPerOrgObj[s.organization.name] = 0; }
      sessionCountPerOrgObj[s.organization.name] = sessionCountPerOrgObj[s.organization.name] + 1;

      let date = new Date(s.sessionStartTime).toISOString().split('T')[0];
      const dateArr = date.split('-');
      date = dateArr[1] + '-' + dateArr[2] + '-' + dateArr[0];
      // console.log('=====> DATE:', index, date, s._id)

      if (!sessionsPerDate[date]) sessionsPerDate[date] = 0;
      sessionsPerDate[date] = sessionsPerDate[date] + 1;
      if (!sessionTimePerDate[date]) sessionTimePerDate[date] = 0;
      sessionTimePerDate[date] = sessionTimePerDate[date] + s.sessionLength / 1000 / 60;

      // FOR modulesPerName, create a SET, unique by moduleName
      if (true || s.user.name === 'jcrist70') {
        // console.log('!!! s.user.name, sessionsPerName[s.user.name]', s.user.name, sessionsPerName[s.user.name])
        if (!sessionsPerName[s.user.name]) { 
          sessionsPerName[s.user.name] = [s];
          modulesPerName[s.user.name] = [s.unitysessionmodules];
          modulesPerName[s.user.name] = modulesPerName[s.user.name].flat();
          // console.log('---> does not exist s', s, sessionsPerName[s.user.name], modulesPerName[s.user.name])
        } else {
          sessionsPerName[s.user.name] = [...sessionsPerName[s.user.name], s];
          modulesPerName[s.user.name] = [...modulesPerName[s.user.name], s.unitysessionmodules];
          modulesPerName[s.user.name] = modulesPerName[s.user.name].flat();
          // console.log('---> exists s', s, sessionsPerName[s.user.name], modulesPerName[s.user.name])
        };
      }
      // console.log('LEADER DATA modulesPerName:', modulesPerName)
      // let modulesPerNameKeys = Object.keys(modulesPerName);

      // modulesPerNameKeys.forEach((a) => {
        // console.log('a:', a)
        // if (!uniqueModuleNamesPerUser[s.user.name]) {
        //   uniqueModuleNamesPerUser[s.user.name] = [];
        // }
        // modulesPerName[a].forEach((m) => {
        //   if (!uniqueModulesPerName.includes(m.moduleName)) {
        //     uniqueModulesPerName.push(m.moduleName);
        //   }
        // })
        uniqueModuleNamesPerUser[s.user.name] = uniqueModulesPerName;

      // })
      // console.log('LEADER DATA uniqueModuleNamesPerUser:', uniqueModuleNamesPerUser)

      const names = Object.keys(modulesPerName);
      names.forEach((n, i) => {
        let arrUniqueMuduleIds = [];
        modulesPerName[n].forEach((m, i) => {
          // console.log('m._id:', m._id)
          // if (arrUniqueMuduleIds.indexOf(m._id) === -1) {
          //   arrUniqueMuduleIds.push(m._id);
          if (arrUniqueMuduleIds.indexOf(m.moduleName) === -1) {
            arrUniqueMuduleIds.push(m.moduleName);
            if (m.userScore !== -1) {
              if (!moduleCountPerName[n]) {
                moduleCountPerName[n] = 1;
              } else {
                moduleCountPerName[n] =  moduleCountPerName[n]+1;
              }
            }
          } else {
            // DUPLICATE
            // console.log('DUPLICATE', n, i)
          }
        })
        moduleCountPerName[n] = arrUniqueMuduleIds.length;
        // console.log('arrUniqueMuduleIds.length:', n, arrUniqueMuduleIds.length)
        // console.log('n, moduleCountPerName[n]:', n, moduleCountPerName[n])
      })

      // ITERATE over MODULES per SESSION
      s.unitysessionmodules.forEach((m, i) => {
        // console.log('====> i, m:', m.moduleName , i, m)
        // UPDATE 032625: include -1 scores in negotiation totals
        // if (m.moduleType === 'negotiation' && m.userScore !== -1) {
        if (m.moduleType === 'negotiation') {
          // Accumulate Negotiation Total Negotiation Time in mS
          time += m.endTime - m.startTime;
          negotiations += 1;
          lengths.push(m.endTime - m.startTime);

          if (!negotiationsPerUser[s.user.name]) negotiationsPerUser[s.user.name] = [];
          negotiationsPerUser[s.user.name] = [...negotiationsPerUser[s.user.name], m];

          if (!negotiationTimePerDate[date]) negotiationTimePerDate[date] = 0;
          negotiationTimePerDate[date] = negotiationTimePerDate[date] + (m.endTime - m.startTime) / 1000 / 60;

          if (!negotiationsPerSession[index]) { negotiationsPerSession[index] = []; }
          negotiationsPerSession[index] = [...negotiationsPerSession[index], m];

          if (!lengthsObj[s.user.name]) lengthsObj[s.user.name] = 0;
          lengthsObj[s.user.name] = lengthsObj[s.user.name] + (m.endTime - m.startTime) / 1000 / 60;
        }
        names.push(s.user.name);
      });
      
    }
  });

  // pack all prepared data into dataObj and return
  const userDataObj = {
    duration: (duration / 1000 / 60).toFixed(),
    sessions,
    sessionCount,
    sessionCountObj,
    sessionCountPerOrgObj,
    sessionsPerDate,
    sessionsPerName,
    modulesPerName,
    moduleCountPerName,
    sessionTimePerDate,
    negotiationTimePerDate,
    negotiationsPerSession,
    negotiationsPerUser,
    negotiations,
    time,
    lengths,
    lengthsObj,
    modules,
    uniqueModuleNamesPerUser
  };
  // console.log('========> procLeadUnityData SENDING:', userDataObj)
  return { userDataObj };
};

// --------
// TRAINEE
// --------
export const procTraineeUnityData = async (usrData) => {
  let duration = 0;
  let negotiations = 0;
  let time = 0;
  let modules = 0;
  const names = [];
  const sessions = [];
  let sessionCount = 0;
  const moduleScoreObj = {};
  const totalModulesByType = {};

  // ITERATE over all SESSIONS
  usrData.forEach((s, index) => {
    modules += s.unitysessionmodules.length;

    // DO NOT ADD IF MISSING DATA
    if (
      s.sessionLength &&
      s.sessionLength > 0 &&
      modules > 0 &&
      s.unitysessionmodules.length > 0 &&
      s.user
    ) {
      // console.log('--------------> UNITY data index, s:',index, s)
      sessions.push(s);
      sessionCount += 1;
      duration += s.sessionLength;

      let date = new Date(s.sessionStartTime).toISOString().split('T')[0];
      const dateArr = date.split('-');
      date = dateArr[1] + '-' + dateArr[2] + '-' + dateArr[0];

      // ITERATE over MODULES per SESSION
      s.unitysessionmodules.forEach((m, i) => {
        // console.log('====> i, m:', m.moduleName , i, m)
        if (m.moduleType === 'negotiation' && m.userScore !== -1) {
          // Accumulate Negotiation Total Negotiation Time in mS
          time += m.endTime - m.startTime;
          if (!totalModulesByType[m.moduleName]) { totalModulesByType[m.moduleName] = []; }
          totalModulesByType[m.moduleName] = [
            ...totalModulesByType[m.moduleName],
            { s: index, m: i, type: m.moduleType, user: s.user.name }
          ];

          if (!moduleScoreObj[m.moduleName]) moduleScoreObj[m.moduleName] = [];
          moduleScoreObj[m.moduleName] = [
            ...moduleScoreObj[m.moduleName],
            m.userScore
          ];

          negotiations += 1;
        }
        names.push(s.user.name);
      });
    }
  });

  // pack all prepared data into dataObj and return
  const userDataObj = {
    duration: (duration / 1000 / 60).toFixed(),
    sessions,
    sessionCount,
    negotiations,
    time,
    modules,
    totalModulesByType,
    moduleScoreObj
  };
  // console.log('========> fetchAdminUnityData SENDING:', userDataObj)
  return { userDataObj };
};
