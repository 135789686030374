import React, { useMemo } from 'react';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';

const Table3 = ({ data, reactVerbosity }) => {
  // console.log('Table3 RENDERING');
  // data.forEach((s, i) => {
  //   const countStr = `${s.sessionCount}/${s.sessionCountOrg}`;
  //   data[i].sessionCount = countStr;
  // });
  reactVerbosity >= 2 && console.log('###################> TABLE3 DATA:', data);
  // should be memoized or stable

  const columns = useMemo(() => [
    {
      accessorKey: 'organization',
      header: 'Organization'
    },
    {
      accessorKey: 'name',
      header: 'Name'
    },

    {
      accessorKey: 'modules',
      header: 'Modules'
    },
    {
      accessorKey: 'sessionCount', // normal accessorKey
      header: 'Sessions (Trainee/Org)'
    },
    {
      accessorKey: 'negotiations',
      header: 'Negotiations'
    },
    {
      accessorKey: 'negotiationTime',
      header: 'Negotiation Time (min)'
    }
  ]);

  const table = useMantineReactTable({
    columns,
    data,
    enableColumnResizing: true,
    enableGrouping: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    initialState: {
      density: 'xs',
      expanded: true,
      // grouping: ['negotiation'],
      pagination: { pageIndex: 0, pageSize: 20 }
      // sorting: [{ id: 'negotiation', desc: false }],
    },
    // mantineToolbarAlertBannerBadgeProps: { color: 'blue', variant: 'outline' },
    // mantineTableContainerProps: { sx: { maxHeight: 700 } },
    mantineTableContainerProps: { sx: { height: '80%' } }
  });

  return <MantineReactTable table={table} />;
};

export default React.memo(Table3);

// mantineTableBodyRowProps={{
//   sx: {
//     height: '10px'
//   }
// }} mantineTableBodyCellProps={{
//   sx: {
//     padding: '2px 16px'
//   }
// }}
// mantinePaperProps={{
//   shadow: 'xs'
// }}
