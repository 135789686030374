import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
// RTKQ
// import { pagesApi } from '../apis/rtkPage.api';
// REDUCERS
import appSlice from './app.slice';
import userSlice from './user.slice';
import { dataApi } from '../apis/rtkData.api';

const store = configureStore({
  reducer: {
    app: appSlice,
    user: userSlice,
    [dataApi.reducerPath]: dataApi.reducer
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false
    }).concat(dataApi.middleware);
    // return getDefaultMiddleware()
    // .concat(dataApi.middleware);
  }
});
setupListeners(store.dispatch);

export default store;
