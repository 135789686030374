import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';

import { auth } from '../config/fb';
// REDUX
import {
  resetUser,
  setLoginStatus,
  setEmailVerified
} from '../redux/user.slice';
import {
  setMode,
  setReset,
  setSentTokenMessage,
  unloadUnityApp
} from '../redux/app.slice';
// API
import { deleteSession, invalidateUserToken } from '../apis/user.api';
import { updateUnityAppLoginStatusApi } from '../apis/app.api';
// Images
import Logo from '../images/lavenir-logo.png';

const UserNav = () => {
  // console.log('UserNav RENDERING');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userName, setUserName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [isPlaying, setIsPlaying] = useState(true); // eslint-disable-line
  const [org, setOrg] = useState('');
  // const { app, user } = useSelector((state) => ({ ...state })); // eslint-disable-line

  const { unityAppRunning } = useSelector((state) => state.app, shallowEqual);
  const { sentTokenMessage } = useSelector((state) => state.app, shallowEqual);
  const { reactVerbosity } = useSelector((state) => state.app);
  const { mode } = useSelector((state) => state.app, shallowEqual);
  const { currentPage } = useSelector((state) => state.app, shallowEqual);
  const { loginStatus  } = useSelector((state) => state.user, shallowEqual); // eslint-disable-line
  const { role } = useSelector((state) => state.user.user, shallowEqual);
  const { name } = useSelector((state) => state.user.user, shallowEqual);
  const { organization } = useSelector((state) => state.user.user, shallowEqual);
  const { email } = useSelector((state) => state.user.user, shallowEqual);

  // useEffect(() => {
  //   console.log('UserNav ==> sentTokenMessage, currentPage:', sentTokenMessage, currentPage)
  // }, [sentTokenMessage, currentPage])

  useEffect(() => {
    const setUserInfo = async () => {
      await setUserName(name);
      await setFirstName(name.split(' ')[0]);
      if (organization.name) {
        await setOrg(organization.name);
      } else {
        await setOrg(organization);
      }
    };

    setTimeout(() => {
      if (typeof name === 'string' && name.length > 0 && organization != null) {
        setUserInfo();
      }
    }, 100);
  }, [name, organization]);

  const handleClickOverlay = () => {
    // TODO: DECIDE TO NOTIFY USER TO WAIT FOR APP TO FINISH LOADING
    // console.log('OVERLAY CLICK')
  };

  const logOutHandler = async () => {
    const application = {userEmail: email, loginStatus: false};
    await updateUnityAppLoginStatusApi(application);
        
    signOut(auth)
      .then(async (res) => {
        // Sign-out successful
        await dispatch(setLoginStatus(false));
        await dispatch(setEmailVerified(false));
        await dispatch(resetUser());
        
        await deleteSession();
        // TODO: clear user session, will need a call to backend..
        setTimeout(() => {
          navigate('/login', { replace: true });
        }, 0);
      })
      .catch((error) => {
        reactVerbosity > 0 && console.log('ERROR SIGNING OUT:', error);
      });
  };

  const handleMode = async () => {
    if (mode === 'dev') {
      await dispatch(setMode('prod'));
    } else if (mode === 'prod') {
      await dispatch(setMode('dev'));
    }
  };
  const navToDash = () => {
    console.log("!!!!navToDash!!!!", role)
    if (role === 'admin') {
      console.log("/admin/dash")
      navigate('/admin/dash', { replace: true });
    } else if (role === 'leader') {
      navigate('/leader/dash', { replace: true });
    } else if (role === 'trainee') {
      navigate('/trainee/dash', { replace: true });
    } else {
      // User that has not been assigned a role
      navigate('/');
    }
  };

  const handleLogoNav = () => {
    if (currentPage !== 'login-entraid') {

      if (!unityAppRunning) {
        console.log('** unity app NOT running **')
        setIsPlaying(false);
        dispatch(setSentTokenMessage(false));
        navigate('/');
      } else {
        console.log('** unity app running, setting unloadUnityApp to true **')
        dispatch(unloadUnityApp(true));
      }

    }
  };

  const handleLoginNav = () => {
    // console.log("----> handleLoginNav", unityAppRunning)
      if (!unityAppRunning) {
        setIsPlaying(false);
        dispatch(setSentTokenMessage(false));
        dispatch(setReset(true));
        // window.location.replace(`/login`);
      } else {
        dispatch(unloadUnityApp(true));
      }

  };
  const handleRegisterNav = () => {
    // console.log("----> handleLoginNav", unityAppRunning)
    if (!unityAppRunning) {
      setIsPlaying(false);
      dispatch(setSentTokenMessage(false));
      dispatch(setReset(true));
      // window.location.replace(`/login`);
    } else {
      dispatch(unloadUnityApp(true));
    }
  };

  return (
    <div>
      <div className="">
        <div className="header">
          <nav className="header-grid">
            
            {currentPage === 'unityApp' && !sentTokenMessage && (
              <div
                className="header-page-nav-overlay"
                style={{
                  backgroundColor: !sentTokenMessage ? 'red' : 'blue'
                }}
                onClick={handleClickOverlay}
              ></div>
            )}
            
            <div className="header-logo" onClick={handleLogoNav}>
              <img src={Logo} style={{ width: '200px' }} alt="lavenirai" />
            </div>
            {<div className="header-verified">
              {false && role && loginStatus && firstName && org && currentPage !== 'adminDash' && currentPage !== 'leaderDash' && currentPage !== 'traineeDash' && currentPage !== 'administrator' &&
                <p style={{ width: "250px" }}>Hi {firstName}, welcome back!</p>
              }
            </div>}

            {currentPage === 'adminDash' && role === 'admin' && (
              <div className="header-page-title">Admin Insights</div>
            )}
            {currentPage === 'leaderDash' &&
              role === 'leader' && (
                <div className="header-page-title">Team Lead Insights</div>
            )}
            {currentPage === 'traineeDash' &&
              role === 'trainee' && (
                <div className="header-page-title">Trainee Insights</div>
            )}
            {currentPage === 'administrator' &&
              role === 'admin' && (
                <div className="header-page-title">System Administrator</div>
            )}
            {currentPage === 'logengine' &&
              role === 'admin' && (
                <div className="header-page-title">Log Engine</div>
            )}

            {false && currentPage !== 'login' && <div className="header-user-guide">
              <button className="user-guide-link" onClick={() => invalidateUserToken()}>
                Invalidate
              </button>
            </div>}

            {false && currentPage !== 'unityApp' && <div className="header-user-guide">
              <Link className="user-guide-link" to="/user-guide">
                User Guide
              </Link>
            </div>}
            {currentPage !== 'unityApp' && <div className="header-contact-support">
              <Link className="contact-support-link" to="https://lavenirai.com/support">
                Contact Support
              </Link>
            </div>}

            {<div className="header-login-insights">
              {loginStatus && role && currentPage !== 'unityApp' && (
                  <Link className="login-link" to={role === 'admin' && "/admin/dash" || role === 'leader' && "/leader/dash" || role === 'trainee' && "/trainee/dash"} onClick={navToDash}>
                    Insights
                  </Link>
              )}
            </div>}

            {!role && !loginStatus && currentPage !== 'login-entraid' && (
              <div className="header-register-logout">
                <Link className="register-link" to="/register" onClick={handleRegisterNav}>
                  Register
                </Link>
              </div>
            )}
            {!role && !loginStatus && currentPage !== 'login-entraid' && (
              <div className="header-login-insights">
                <Link className="login-link" to="/login" onClick={handleLoginNav}>
                  Login
                </Link>
              </div>
            )}
            {role && currentPage !== 'unityApp' && loginStatus && (
              <div className="header-register-logout">
                <Link className="logout-link" onClick={logOutHandler}>
                  Log Out
                </Link>
              </div>
            )}
            
          </nav>
        </div>
      </div>
    </div>
  );
};

export default React.memo(UserNav);

// {(!loginStatus || !role) && currentPage !== 'login' && (
// && currentPage !== 'register'

// {false && <div className="header-insights">
//               {currentPage !== 'unityApp' &&
//                 currentPage !== 'adminDash' &&
//                 currentPage !== 'leaderDash' &&
//                 currentPage !== 'traineeDash' &&
//                 loginStatus && role && (
//                   <button className="dashboard-button" onClick={navToDash}>
//                     Insights
//                   </button>
//               )}
//             </div>}
