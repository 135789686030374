import axios from 'axios';

export const getUnityModuleCount = async () => {
  const axiosInstance = axios.create({
    withCredentials: true
  });
  const response = await axiosInstance.get(
    process.env.REACT_APP_API + '/modules/count'
  );
  // console.log('===> API MODULE COUNT:', response)
  return response;
};

export const reloadSession = async () => {
  const promise = await new Promise((resolve, reject) => {
    const axiosInstance = axios.create({
      withCredentials: true
    });
    const response = axiosInstance.get(
      process.env.REACT_APP_API + '/user/reload/session'
    );
    // console.log('-----------> /user/reload/session RESPONSE:', response)
    resolve(response);
  });
  // console.log('----------> PROMISE:', promise)
  return promise;
};

export const deleteSession = async () => {
  const promise = await new Promise((resolve, reject) => {
    const axiosInstance = axios.create({
      withCredentials: true
    });
    try {
      const response = axiosInstance.get(
        process.env.REACT_APP_API + '/user/delete/session'
      );
      // console.log('-----------> /user/delete/session RESPONSE:', response);
      resolve(response);
    } catch (err) {
      reject(err);
    }
  });
  // console.log('----------> PROMISE:', promise)
  return promise;
};

export const setCurrentUser = async (authtoken, user, email, userCredential) => {
  // console.log('----------> setCurrentUser authtoken (49):', authtoken)
  // console.log('-----> setCurrentUser user (50):', user)
  const config = {
    headers: {
      authtoken
      // user
    }
  };
  const promise = await new Promise((resolve, reject) => {
    const axiosInstance = axios.create({
      withCredentials: true
    });
    const response = axiosInstance.post(
      process.env.REACT_APP_API + '/user/set/current',
      { user, email, userCredential },
      config
    );
    // console.log('-----------> setCurrentUser RESPONSE (66):', response)
    resolve(response);
  });
  // console.log('----------> PROMISE:', promise)
  return promise;
};

export const updateCurrentUserTokens = async (idToken, email) => {
  const promise = await new Promise((resolve, reject) => {
    const axiosInstance = axios.create({
      withCredentials: true
    });
    const response = axiosInstance.post(
      process.env.REACT_APP_API + '/user/update/token',
      { idToken, email },
      {
        headers: {
          authtoken: idToken
        }
      }
    );
    // console.log('-----------> setCurrentUser RESPONSE:', response)
    resolve(response);
  });
  // console.log('updateCurrentUserTokens ----------> PROMISE:', promise)
  return promise;
};

export const createUser = async (authtoken, user, org) => {
  // console.log(`calling get-user AUTHTOKEN: `, authtoken)
  // console.log('===> calling createUser USER: ', user);
  return await axios.post(
    process.env.REACT_APP_API + '/create-user',
    {
      user,
      org
    },
    {
      headers: {
        authtoken
      }
    }
  );
};

export const getUnitySessionData = async (authtoken, user, selection) => {
  // console.log('----------> setCurrentUser authtoken:', authtoken)
  const axiosInstance = axios.create({
    withCredentials: true
  });
  const config = {
    headers: {
      authtoken
    }
  };
  const promise = await new Promise((resolve, reject) => {
    const response = axiosInstance.post(
      process.env.REACT_APP_API + '/unity/user/data',
      { user, selection },
      config
    );
    // console.log('-----------> /user/data server RESPONSE:', response)
    resolve(response);
  });
  // console.log('----------> PROMISE:', promise)
  return promise;
};

// 
export const invalidateUserToken = async (authtoken) => {
  const axiosInstance = axios.create({
    withCredentials: true
  });
  const config = {
    headers: {
      authtoken
    }
  };
  const promise = await new Promise((resolve, reject) => {
    const response = axiosInstance.get(
      process.env.REACT_APP_API + '/user/invalidate/token');
    // console.log('-----------> /user/data server RESPONSE:', response)
    resolve(response);
  });
  // console.log('----------> PROMISE:', promise)
  return promise;
}
