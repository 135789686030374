import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ReactSelect from 'react-select';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FileSaver from 'file-saver';

//COMPONENTS
import DateSelection from '../components/DateSelectionLE';
import TableLogData from '../components/TableLogData';
// REDUX
import { setCurrentPage } from '../redux/app.slice';
// API
import { queryApi, getServerSelectionApi, updateAiLogsApi } from '../apis/logEngine.api';

// messagesStr = messagesStr.replace(/\\n/g, "__")
// messagesStr = messagesStr.replace(/\(/g, " ");
// messagesStr = messagesStr.replace(/\)/g, " ");
// messagesStr = messagesStr.replace(/\\+/g, "")
// messagesStr = messagesStr.replace(/\\/g, "")
// messagesStr = messagesStr.replace(/\"/g, '') 

// TABLES
// payload
// picked_deployment
// model_request
// raw_model_response
// convai_returned_response
// service_errors
// service_start

// TEST QUERIES
// SELECT * FROM model_request WHERE request_id = '[30af6dc2-74df-4f3a-a0db-f83570d153ca]';
// SELECT * FROM payload WHERE request_id = '[b3dde2f8-6c7d-465b-a3b9-3c773470ec83]';
// SELECT * FROM raw_model_response WHERE request_id = '[b3dde2f8-6c7d-465b-a3b9-3c773470ec83]';
// [b3dde2f8-6c7d-465b-a3b9-3c773470ec83], [6ceaf716-9e79-499a-afae-4e94662d2f71], [9d0fa9cb-c7d4-4ec0-80b5-f6c60b83f036]

// SELECT * FROM payload WHERE timestamp = '2024-06-04T15:17:17.000Z';
// SELECT * FROM payload WHERE timestamp >= '2024-06-04' AND timestamp < '2024-06-04T15:00:00.000Z';

const customStyles = {
    option: provided => ({
      ...provided,
      color: 'black'
    }),
    control: provided => ({
      ...provided,
      color: 'black',
      width: '350px',
    //   marginLeft: '100px',
    }),
    singleValue: provided => ({
      ...provided,
      color: 'black'
    })
  }

const LogEngine = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [isOpen, setIsOpen] = useState(true); // eslint-disable-line
    const [selectedServer, setSelectedServer] = useState({label: 'staging-us', value: 'http://3.222.250.110'});
    const [ serverArr, setServerSelection ] = useState([]);

    const [ queryString, setQueryString ] = useState(`SELECT * FROM payload WHERE timestamp >= '2024-06-04' AND timestamp < '2024-06-04T15:00:00.000Z';`);
    const [ queryResponseHTML, setQueryResponseHTML ] = useState(null);
    const [ tableChoicesVisible, setTableChoicesVisible ] = useState(false);
    const [ useAi, setUseAi ] = useState(false);
    const [ useDateSelection, setUseDateSelection ] = useState(false);

    const { loginStatus  } = useSelector((state) => state.user); // eslint-disable-line
    const { role } = useSelector((state) => state.user.user);
    const { user } = useSelector((state) => state.user, shallowEqual); // eslint-disable-line

    const schemaLink = <a href='/schema' style={{ marginLeft: '10px', marginRight: '25px' }}>schema</a>;
    const documentationLink = <a href='/documentation'>documentation</a>;
  
    useEffect(() => {
        if (loginStatus && role) {
          dispatch(setCurrentPage('logengine'));
        } else {
          dispatch(setCurrentPage('login'));
          navigate('/login', { replace: true });
        }
      }, []);
    useEffect(() => {
        if (loginStatus && role) {
            getServerSelection();
        } 
      }, []);
    
    const getServerSelection = async () => {
        const selectionRes = await getServerSelectionApi();
        // console.log('selectionArr:', selectionRes.data)
        setServerSelection(selectionRes.data);
    }
    
    const setDefaultDates = async (start, end) => {
      // console.log('setDefaultDates:', start, end)
      await setStartDate(start);
      await setEndDate(end);
    };
    const handleStartDate = (date) => {
        setStartDate(date);
    };
    const handleEndDate = (date) => {
        setEndDate(date);
    };


    const handleServerSelection = (selectedOption) => {
        // console.log('selectedOption:', selectedOption)
        setSelectedServer(selectedOption);
    }

    const handleUpdateAiLogs = async () => {
      if (selectedServer != null) {
        const result = await updateAiLogsApi(user.idToken, selectedServer);
        // console.log('handleUpdateAiLogs:', result.data)
        if (result.data && result.data.err != null) {
          toast(`Update Logs FAILURE: ${result.data}`)
        } else {
          toast(`Ai Logs Updating`)
        }
      } else {
        toast('You must select a server first!');
      }
    }

    const handleQueryLogs = async () => {

      await setQueryResponseHTML('fetching');
      
      const selection = {
        startDate,
        endDate
      }
      // "and Date between 2011/02/25 and 2011/02/27"
      // queryString += `and Date between ${startDate} and ${endDate}`;
      // const queryStringWithSelection = queryString + ` between ${startDate.toLocaleDateString('en-US', {year:'2-digit', month:'2-digit', day:'2-digit'})} and ${endDate.toLocaleDateString('en-US', {year:'2-digit', month:'2-digit', day:'2-digit'})}`;
      let queryStringWithSelection = queryString;
      if (useDateSelection) {
        queryStringWithSelection += ` between ${startDate} and ${endDate}`;
      }
      
      console.log('queryStringWithSelection:', queryStringWithSelection)
      let queryResponse = null;
      if (useAi) {
        queryResponse = await queryApi(selectedServer, queryStringWithSelection, useAi, selection)
      } else {
        queryResponse = await queryApi(selectedServer, queryString, useAi, selection)
      }
      
      console.log('queryString, queryResponse:', queryString, queryResponse.data)
      setQueryResponseHTML(queryResponse.data);
    }

    const RawDataComponent = () => {

      console.log('RawDataComponent queryResponseHTML:', queryResponseHTML)
       
      if (useAi) {
        // return <div className="content raw-data-component" dangerouslySetInnerHTML={{__html: queryResponseHTML}}></div>
        return <div className="content raw-data-component" dangerouslySetInnerHTML={{__html: JSON.stringify(queryResponseHTML)}}></div>
      } else if (queryResponseHTML == null) {
        return <div className="content raw-data-component">Ready for queries..</div>
      } else if (queryResponseHTML === 'fetching') {
        return <div className="content raw-data-component">..fetching data from the DB</div>
      } else if (queryResponseHTML.err != null) {
        return <div className="content raw-data-component">{queryResponseHTML.err}</div>
      } else {
        return (
        <div className="content raw-data-component">
          <div>SERVER: {queryResponseHTML && JSON.stringify(queryResponseHTML.server)}</div>
          <div>QUERY: {queryResponseHTML && JSON.stringify(queryResponseHTML.query)}</div>
          <div>DATA: {queryResponseHTML && JSON.stringify(queryResponseHTML.data)}</div>
        </div>
        )
      }

      // if (useAi) {
      //   // return <div className="content raw-data-component" dangerouslySetInnerHTML={{__html: queryResponseHTML}}></div>
      //   return <div className="content raw-data-component" dangerouslySetInnerHTML={{__html: JSON.stringify(queryResponseHTML)}}></div>
      // } else {
      //   return <div className="content raw-data-component" dangerouslySetInnerHTML={{__html: JSON.stringify(queryResponseHTML)}}></div>
      // } 
    }

    const handleSaveHTML = async () => {
      const blob = new Blob([queryResponseHTML], { type: 'text/html' });
      FileSaver.saveAs(blob, `queryResponseHTML_${new Date()}.html`);
    }
    const handleSaveJSON = async () => {
      const blob = new Blob([JSON.stringify(queryResponseHTML)], { type: 'application/json' });
      FileSaver.saveAs(blob, `queryResponseHTML_${new Date()}.json`);
    }

    const TableChoices = () => {
      //'table options: model_request, convai_returned_response, picked_deployment, payload'
      return (
        <ul style={{ minWidth: '61.5%', marginLeft: '50px' }}>
          <li>payload</li>
          <li>picked_deployment</li>
          <li>model_request</li>
          <li>raw_model_response</li>
          <li>convai_returned_response</li>
          <li>service_errors</li>
          <li>service_start</li>
          <li>SELECT * FROM payload WHERE timestamp &gt;= '2024-06-04' AND timestamp &lt; '2024-06-04T15:00:00.000Z';</li>
          <li>SELECT * FROM raw_model_response WHERE request_id = '[b3dde2f8-6c7d-465b-a3b9-3c773470ec83]';</li>
        </ul>
      )
    }

    const handleUpdateUseAi = () => {
      if (useAi) {
        setUseAi(false);
      } else {
        setUseAi(true);
        setUseDateSelection(false);
      }
      // setUseAi((pre) => setUseAi(!pre));
      if (!useAi) {
        setQueryString('Give me last response returned to user with id user_124');
      } else {
        // setQueryString('SELECT * FROM picked_deployment;');
        setQueryString(`SELECT * FROM model_request WHERE request_id = '[30af6dc2-74df-4f3a-a0db-f83570d153ca]';`)
      }
    }
    const handleUseSelection = () => {
      setUseDateSelection((pre) => setUseDateSelection(!pre));
    }

    const handleUpdateQueryString = (value) => {
      let queryStr = value.replace(/DROP/, "SELECT").replace(/GRANT/, "SELECT").replace(/TRUNCATE/, "SELECT");
      queryStr = queryStr.replace(/DELETE/, "SELECT").replace(/INSERT/, "SELECT");
      // console.log('!!!! ---> queryStr:', queryStr)
      setQueryString(value);
    }

    return (
        <div className='logengine-page-container'>
        <div className="base-page home-page-background">
        <div className="logengine-page-grid">
            <div className='logengine-page-server-selector'>
                Server: 
                <ReactSelect
                    isOpen={isOpen}
                    value={selectedServer}
                    className="badge text-black"
                    options={serverArr}
                    onChange={handleServerSelection}
                    styles={customStyles}
                >
                </ReactSelect>
            </div>
            <div className="logengine-page-date-range-selector">
              {useAi && (
                <DateSelection
                  start={startDate}
                  end={endDate}
                  setDefaultDates={setDefaultDates}
                  handleStartDate={handleStartDate}
                  handleEndDate={handleEndDate}
                  handleUseSelection={handleUseSelection}
                  useDateSelection={useDateSelection}
                  setUseDateSelection={setUseDateSelection}
                />
              )}
            </div>
            
            <div className='logengine-page-query-control'> 
            <p onClick={() => setTableChoicesVisible(!tableChoicesVisible)} style={{ color: tableChoicesVisible  ? 'green' : 'black' }} >Query:</p>
                <div className='logengine-page-query-input'>
                    <textarea rows="5" type='text' style={{ width: '100%' }} value={queryString} placeholder='enter query text here' onChange={(e) => handleUpdateQueryString(e.target.value)}/>
                </div>
                <button className='logengine-page-query-button' onClick={handleQueryLogs}>Fetch</button>
            </div>
            {false && <div className='logengine-page-query-status'>
                
            </div>}
            <div className='logengine-page-bottom-control-bar'>
                
                <div className='logengine-page-ai-checkbox-label'>AI ON:</div>
                <input className='logengine-page-ai-checkbox' type="checkbox" onChange={handleUpdateUseAi} />
                
                {false && <div className='logengine-page-bottom-control-bar-links '>
                    {schemaLink} {documentationLink}
                </div>}
                
                <div className='logengine-page-status-message'>{queryResponseHTML && queryResponseHTML.err ? queryResponseHTML.err : <>System Status Messages</>}</div>
                
                <div className='logengine-page-bottom-control-bar-buttons'>
                  <div>
                    <button className='logengine-page-bottom-control-bar-button' onClick={handleSaveJSON}>Download Raw</button>
                    <button className='logengine-page-bottom-control-bar-button' onClick={handleUpdateAiLogs}>Update Logs</button>
                  </div>
                </div>
            
            </div>

            <div className='logengine-page-raw-query-response'>
              {tableChoicesVisible && <TableChoices className='logengine-page-status-message' />} 
              {!tableChoicesVisible && <RawDataComponent className='raw-data-component'/>}
              {false && <TableLogData data={queryResponseHTML}/>}
            </div>
            <div className='logengine-page-bottom-buffer'></div>
        </div>
        </div>
        </div>
    );
};

export default LogEngine;