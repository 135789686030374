import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';

// API
import { updateUnityAppVerbosityApi } from '../apis/app.api';
import { deleteSession } from '../apis/user.api';
import {
  verifyUser,
  addOrganizationToDB,
  fetchOrganizationsApi,
  fetchUserCacheApi,
  addUserToCache
} from '../apis/admin.api';
import { deleteUserSessions, createBackupOfDB, createCustomChapterGroupApi } from '../apis/data.api';
// PROC
// REDUX
import { setAppVerbosity, setCurrentPage } from '../redux/app.slice';

const Administrator = () => {
  // console.log('Administrator RENDERING');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { app, user } = useSelector((state) => ({ ...state })); // eslint-disable-line

  const [verbosity, setVerbosity] = useState(app.verbosity);
  const [queuedUserArray, setQueuedUserArray] = useState([]);
  const [organizationArray, setOrganizationArray] = useState([]);
  const [organizationOptions, setOrganizationOptions] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState('');
  const [orgName, setOrgName] = useState('');
  const [orgAddress, setOrgAddress] = useState('');
  const [orgContact, setOrgContact] = useState('');
  const [orgEmail, setOrgEmail] = useState('');
  const [orgPhone, setOrgPhone] = useState(""); // eslint-disable-line
  const [orgCredits, setOrgCredits] = useState(0);
  const [delEmail, setDelEmail] = useState('');

  const [userName, setUserName] = useState('');
  const [userOrg, setUserOrg] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userRole, setUserRole] = useState('trainee');
  const [addUserToCacheResponse, setAddUserToCacheResponse] = useState({data: ''})
  const [addOrganizationToDBResponse, setAddOrganizationToDBResponse] = useState({data: ''})

  const [userLoggedIn, setUserLoggedIn] = useState(false); // eslint-disable-line

  const [pdfUrl, setPdfUrl] = useState('');

  useEffect(() => {
    dispatch(setCurrentPage('administrator'));
    setVerbosity(app.verbosity);
  }, []);

  useEffect(() => {
    // console.log(
    //   'Administrator ===============> user:',
    //   user,
    //   user.user.idToken
    // );
    const orgRes = fetchOrganizationsApi(user.user.idToken);  // eslint-disable-line
    // console.log('===============> orgArr:', orgRes.data);
  }, []);

  useEffect(() => {
    const verify = async () => {
      const verification = await verifyUser();
      // console.log("====> verification.data:", verification.data)
      if (!verification.data.loggedIn || verification.data.role !== 'admin') {
        await deleteSession();
        navigate('/login', { replace: true });
      } else {
        await setUserLoggedIn(true);
        // await handleStartDate(new Date('07/01/23'))
      }
    };
    verify();
  }, []);

  useEffect(() => {
    if (user.user.idToken !== null) {
      fetchUserQueue();
      fetchOrganizations();
    }
  }, [user.user.role]);

  const fetchOrganizations = async () => {
    try {
      const orgRes = await fetchOrganizationsApi(user.user.idToken);
      let orgResOptions = [];
      orgRes.data.forEach((o, i) => {
        orgResOptions.push({
          key: i, value: o._id, label: o.name
        })
      })
      // console.log('========> orgArr:', orgResOptions);
      setOrganizationArray(orgRes.data);
      setOrganizationOptions(orgResOptions);
    } catch (err) {
      console.log(err);
    }
  };
  const fetchUserQueue = async () => {
    const cacheRes = await fetchUserCacheApi(user.user.idToken);
    // console.log('========> orgcacheResArr:', cacheRes.data);
    setQueuedUserArray(cacheRes.data);
  };

  const addUser = async () => {
    const newUser = {
      name: userName,
      organization: userOrg,
      email: userEmail.toLowerCase(),
      role: userRole
    };
    const response = await addUserToCache(user.user.idToken, newUser);
    console.log('--> addUserToCacheResponse:', response)
    await setAddUserToCacheResponse(response);
    await fetchUserQueue();
  };
  let customGroupName = `custom-${orgName.toLowerCase()}`;
  customGroupName = customGroupName.split(" ").join("-");
        
  const addOrg = async () => {
    const newOrg = {
      name: orgName,
      contact: orgContact,
      email: orgEmail,
      phone: orgPhone,
      address: orgAddress,
      credits: orgCredits,
      customChapterGroup: customGroupName
    };
    const cg = { 
      name: customGroupName,  
      description: null,
      chapters: [],
      restrictedTo: null,
      lastUpdated: new Date(),
      maintainer: null
    }
    // console.log('-------------> addOrg:', newOrg);
    let response = await addOrganizationToDB(user.user.idToken, newOrg);
    console.log('--> addOrganizationToDB:', response)
    await setAddOrganizationToDBResponse(response);
    await createCustomChapterGroupApi(user.user.idToken, cg);
    await fetchOrganizations();
  };
  const backupDatabase = async () => {
    const response = await createBackupOfDB(user.user.idToken);
    console.log('backupDatabase:', response);
    // TBD check response and modify toast
    toast('DB successfully backed up to S3');
  };
  const deleteUserSessionData = async () => {
    const response = await deleteUserSessions(user.user.idToken, delEmail);
    console.log('deleteUserSessions:', response);
    // TBD check response and modify toast
    toast('Successfully deleted user session data.');
  };

  const updateVerbosity = async () => {
    dispatch(setAppVerbosity(verbosity));
    await updateUnityAppVerbosityApi(user.user.idToken, verbosity);
    toast(`UnityApp verbosity for ${user.user.email} is now set to ${verbosity}`);
  };

  const openNewPage = (newPageUrl) => {
    console.log('openNewPage: ', newPageUrl);
    window.open(newPageUrl, '_blank');
  };

  const updateUserRole = async (value) => {
    await setUserRole(value);
  };

  const handleSelectUserOrg = (selection) => {
    console.log("handleSelectUserOrg:", selection)
    console.log("organizationArray:", organizationArray)
    setSelectedOrg(selection);
    const selectedOrgObj = organizationArray.find((o) => o.name === selection.label);
    setUserOrg(selectedOrgObj.name);
  }
  
  return (
    <div className="page-grid">
      <div className="administrator-page-container">
        <div className="administrator-page">
          <div className="administrator-page-grid">
            <div className="administrator-page-sect-1">
              <div className="administrator-page-header">
                <div className="administrator-page-label">
                  Organizations & User Queue:&nbsp;&nbsp;<p style={{ color: "rgb(133, 224, 249)"}}>{process.env.REACT_APP_NODE_ENV} DB, {process.env.REACT_APP_REGION_CODE}</p>
                </div>
              </div>
            </div>

            <div className="administrator-page-section-1-left">
              <div className="administrator-section-1-left-grid">
                <div className="administrator-section-1-left-grid-row-1">
                  Organization
                </div>
                <div className="administrator-section-1-left-grid-row-2to5">
                  <input
                    type="text"
                    placeholder="Name"
                    onChange={(e) => setOrgName(e.target.value)}
                  />
                  <input
                    type="text"
                    placeholder="Contact Name"
                    onChange={(e) => setOrgContact(e.target.value)}
                  />
                  <input
                    type="text"
                    placeholder="Email"
                    onChange={(e) => setOrgEmail(e.target.value)}
                  />
                  <input
                    type="text"
                    placeholder="Address"
                    onChange={(e) => setOrgAddress(e.target.value)}
                  />
                  <input
                    type="number"
                    placeholder="Credits"
                    onChange={(e) => setOrgCredits(e.target.value)}
                  />
                </div>
                <div className="administrator-section-1-left-grid-row-5">
                  <button onClick={addOrg}>Add Org</button>
                  <div>Response: {typeof addOrganizationToDBResponse.data == 'string' ? addOrganizationToDBResponse.data : 'Success'}</div>
                </div>
              </div>
            </div>

            <div className="administrator-page-section-1-mid">
              <ul>
                <li>Existing:</li>
                {organizationArray &&
                  organizationArray.length > 0 &&
                  organizationArray.map((o) => {
                    return <li key={o.name}>{o.name}</li>;
                  })}
              </ul>
            </div>

            <div className="administrator-page-section-1-right">
              <div className="administrator-section-1-right-grid">
                <div className="administrator-section-1-right-grid-row-1">
                  User Register Cache
                </div>
                <div className="administrator-section-1-right-grid-row-2to5">
                  <input
                    type="text"
                    placeholder="Name"
                    onChange={(e) => setUserName(e.target.value)}
                  />

                  {false && <input
                    type="text"
                    placeholder="Organization"
                    onChange={(e) => setUserOrg(e.target.value)}
                  />}

                  <Select maxMenuHeight={200} value={selectedOrg} options={organizationOptions} onChange={(option) => handleSelectUserOrg(option)} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />  

                  <input
                    type="text"
                    placeholder="Email"
                    onChange={(e) => setUserEmail(e.target.value)}
                  />
                  // THIS IS FROM LOCAL MACHINE
                  <select
                    defaultValue="trainee"
                    className="badge text-white"
                    onChange={(e) => updateUserRole(e.target.value)}
                  >
                    <option value="trainee">trainee</option>
                    <option value="leader">leader</option>
                    <option value="admin">admin</option>
                  </select>
                  {false && userRole}
                </div>
                <div className="administrator-section-1-right-grid-row-5">
                  <button disabled={selectedOrg === ""} onClick={addUser}>Add User</button>
                  <div>Response: {typeof addUserToCacheResponse.data == 'string' ? addUserToCacheResponse.data : 'Success'}</div>
                </div>
              </div>
            </div>

            <div className="administrator-page-section-1-far-right">
              <ul>
                <li>User Cache:</li>
                {queuedUserArray &&
                  queuedUserArray.length > 0 &&
                  queuedUserArray.map((u) => {
                    return (
                      <li key={u.email}>
                        {u.name}, {u.email}, {u.organization}
                      </li>
                    );
                  })}
              </ul>
            </div>

            <div className="administrator-page-sect-3">
              <div className="administrator-page-header">
                <div className="administrator-page-label">
                  Data Base Management
                </div>
              </div>
            </div>
            <div className="administrator-page-section-3-left">
              <div className="administrator-section-1-left-grid">
                <div className="administrator-section-1-left-grid-row-1">
                  Backup Database to S3
                </div>
                <div className="administrator-section-1-left-grid-row-2to5"></div>
                <div className="administrator-section-1-left-grid-row-5">
                  <button onClick={backupDatabase}>Create Backup</button>
                </div>
              </div>
            </div>

            <div className="administrator-page-section-3-right">
              {(user.user.email === 'jcrist50@gmail.com' ||
                user.user.email === 'skygnite@gmail.com') && (
                <div className="administrator-section-1-right-grid">
                  <div className="administrator-section-1-right-grid-row-1">
                    Delete User Session Data
                  </div>
                  <div className="administrator-section-1-right-grid-row-2to5">
                    <input
                      type="text"
                      placeholder="Email"
                      onChange={(e) => setDelEmail(e.target.value)}
                    />
                  </div>
                  <div className="administrator-section-1-right-grid-row-5">
                    <button onClick={deleteUserSessionData}>Delete Data</button>
                  </div>
                </div>
              )}
            </div>

            {false && (
              <div className="administrator-page-section-3-far-right">
                {user.user.email}
              </div>
            )}

            <div className="administrator-page-sect-5">
              <div className="administrator-page-header">
                <div className="administrator-page-label">App Settings</div>
              </div>
            </div>
            <div className="administrator-page-section-5-left">
              <div className="administrator-section-1-left-grid">
                <div className="administrator-section-1-left-grid-row-1">
                  Set Unity App Verbosity
                </div>
                <div className="administrator-section-1-left-grid-row-2to5">
                  <select
                    defaultValue={verbosity}
                    onChange={(e) => setVerbosity(e.target.value)}
                  >
                    <option value={0}>0</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                  </select>
                  <input type='text' value={pdfUrl} placeholder='PDF URL' onChange={(e) => setPdfUrl(e.target.value)}/>
                </div>
                <div className="administrator-section-1-left-grid-row-5">
                  <button onClick={updateVerbosity}>Update Verbosity</button>
                  <button onClick={() => openNewPage(pdfUrl)}>PDF</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Administrator;
