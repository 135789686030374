import axios from 'axios';

export const queryApi = async (server, query, useAi, selection) => {
  console.log('-------> queryApi:', server, query, useAi, selection)
  const axiosInstance = axios.create({
    withCredentials: true
  });
  return await axiosInstance.post(process.env.REACT_APP_API + '/query/ai/logs', {
    server,
    query,
    useAi,
    selection
  });
};
export const getServerSelectionApi = async () => {
  const axiosInstance = axios.create({
    withCredentials: true
  });
  return await axiosInstance.get(process.env.REACT_APP_API + '/get/server/selection');
};
export const updateAiLogsApi = async (authtoken, server) => {
    // console.log('updateAiLogsApi:', server, authtoken)
    const axiosInstance = axios.create({
      withCredentials: true
    });
    const config = {
        headers: {
          authtoken
        }
      };
    try {
        return await axiosInstance.post(process.env.REACT_APP_API + '/update/ai/logs', {
            server
            },
            config
        );
    } catch (err) {
        console.log('ERROR updateAiLogsApi:', err)
        return (err);
    }
};
