import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// IMAGEs
import StephanAtDesk from '../images/StephanAtDesk.png';
// REDUX
import { setUnityAppRunning, setCurrentPage } from '../redux/app.slice';

const APP_URL = process.env.REACT_APP_BASE_URL_LOC;

const Base = () => {
  // console.log('Base RENDERING');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loginStatus  } = useSelector((state) => state.user); // eslint-disable-line
  const { role } = useSelector((state) => state.user.user);
  const { user } = useSelector((state) => state.user); // eslint-disable-line
  
  // useEffect(() => {
  //   console.log('BASE.js idToken ===>>> ', user.idToken)
  // }, [user.idToken])


  useEffect(() => {
    if (loginStatus && role) {
      dispatch(setCurrentPage('home'));
    } else {
      // console.log('REDIRECTING FORM HOME TO LOGIN', new Date())
      dispatch(setCurrentPage('login'));
      navigate('/login', { replace: true });
    }
  }, [loginStatus, role]);

  return (
    <div className="base-page-container">
        {!loginStatus || !role ? (
          <div className="base-page text-warning" style={{ fontSize: '35px', fontWeight: 600, lineHeight: '40px', width: '100vw', height: '50vh', color: 'black', display: 'flex', justifyContent: 'center', alignContent: 'center' }}> LOADING..</div>)
          : 
          (<div className="base-page home-page-background">
            <div className="base-grid">
            {role && (
              <div className="base-page-app-nav-bling">
                <div
                  className="base-page-app-nav"
                  onClick={() => {
                    dispatch(setUnityAppRunning(true));
                    navigate('/app', { replace: true });
                  }}
                >
                  Launch App
                </div>
                {false && <div
                  className="base-page-app-nav"
                  onClick={() => {
                    dispatch(setUnityAppRunning(true));
                    navigate('/app-one', { replace: true });
                  }}
                >
                  Launch App 1
                </div>}
                {false && <div
                  className="base-page-app-nav"
                  onClick={() => {
                    dispatch(setUnityAppRunning(true));
                    navigate('/app-two', { replace: true });
                  }}
                >
                  Launch App 2
                </div>}
              </div>
            )}
            {role && (
              <div className="base-page-insights-nav-bling">
                <div
                  className="base-page-insights-nav"
                  onClick={() => {
                    navigate(`/${role}/dash`);
                  }}
                >
                  Insights
                </div>
              </div>
            )}

            {role && role === 'admin' && (
              <div className="base-page-admin-nav-bling">
                <div className="base-page-admin-nav" onClick={() => {navigate('/administrator');}}>
                  Administrator
                </div>
              </div>
            )}

            {role && role === 'admin' && !APP_URL.includes('app') && (
              <div className="base-page-logengine-nav-bling">
                <div className="base-page-logengine-nav" onClick={() => {navigate('/logengine');}}>
                  Log Engine
                </div>
              </div>
            )}

          </div></div>
            )}
      
    </div>
  );
};

export default Base;
