import React, { useMemo, useState, useEffect } from 'react';
import { Box, Stack, Anchor, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
// import { data } from './trainingProgData';
// import { data } from './makeData2';

const TrainingProgTable = ({ tableData, reactVerbosity, emptyMsg = "You have not completed any training modules yet. " }) => {
  const [columnPinning, setColumnPinning] = useState({});
  const isDesktop = useMediaQuery('(min-width: 1200px)');
  // console.log('TrainingProgTable RENDERING');
  reactVerbosity === 2 &&
    console.log('###################> TrainingProgTable DATA:', tableData);
  // console.log('###################> TrainingProgTable DATA:', tableData);
  const countModules = () => {
    // console.log('data:', data)
    let count = 0;
    let completed = 0;
    tableData.map((c, i) => {
      // console.log('c:', c.userScore)
      if (c.userScore >= 0) {
        completed++;
      }
      return count++;
    });
    // console.log('==> completed, count (27):', completed, count)
    // console.log('{completed, count}:', {completed, count})
    return { completed, count };
  };

  // const {completed, count} = useMemo(
  //   () => countModules(),
  //   [],
  // );

  const { completed, count } = countModules();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (isDesktop) {
        setColumnPinning({
          left: ['mrt-row-expand', 'mrt-row-numbers', 'stateOption'],
          right: ['link'],
        });
      } else {
        setColumnPinning({});
      }
    }
  }, [isDesktop]);

  // const maxComplete = useMemo(
  //   () => tableData.reduce((acc, curr) => Math.max(acc, curr.complete === "\u2713"), 0),
  //   [],
  // );

  const columns = useMemo(() => [
    {
      header: 'Chapter',
      accessorKey: 'chapter'
    },
    {
      header: 'Content',
      accessorKey: 'module',
      size: 200,
    },
    {
      header: 'Complete',
      accessorKey: 'complete',
      size: 20,
      colourOptions: [
        { value: '\u2713', label: 'Complete', color: 'green', isFixed: true }
      ],
      // colourOptions: [{ value: "1", label: 'Complete', color: 'green', isFixed: true }],
      Footer: () => (
        <Stack
          style={{
            marginRight: '150px',
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'center'
          }}
        >
          Progress:
          <Box color="orange">
            {completed}/{count}
          </Box>
        </Stack>
      ),
      Cell: ({ cell }) => (
        <Box
          sx={(theme) => ({
            // backgroundColor:
            //   cell.getValue() === "\u2713"
            //     ? theme.colors.red[9]
            //     : theme.colors.green[9],
            // color: cell.getValue() === "1"
            borderRadius: '4px',
            color:
              cell.getValue() === '\u2713' ? theme.colors.green[9] : theme.colors.red[9],
            maxWidth: '9ch',
            padding: '4px'
          })}
        >
          {cell.getValue()}
        </Box>
      )
    },
    {
      header: 'Date',
      accessorKey: 'date',
      size: 80,
    },
    {
      header: 'Module Type   ',
      accessorKey: 'moduleType',
      size: 110,
    }
    // {
    //   header: 'Chapter',
    //   accessorKey: 'chapter',
    // },
  ]);

  const table = useMantineReactTable({
    columns,
    data: tableData,

    enableColumnResizing: true,
    enableGrouping: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    initialState: {
      density: 'xs',
      expanded: true,
      grouping: ['chapter'],
      pagination: { pageIndex: 0, pageSize: 30 },
      // sorting: [{id: 'complete', desc: true}],
      sorting: [
        { id: 'complete', desc: true },
        { id: 'date', desc: false }
      ]
    },
    // mantineToolbarAlertBannerBadgeProps: { color: 'blue', variant: 'outline' },
    // mantineTableContainerProps: { sx: { maxHeight: 700 } },
    mantineTableContainerProps: { sx: { height: '80%' } },
    renderEmptyRowsFallback: () => <Text style={{ display: "flex", justifyContent: "center"}}>{emptyMsg}</Text>
  });

  return <MantineReactTable table={table} />;
};

export default React.memo(TrainingProgTable);
