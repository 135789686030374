// FIXED RATIO - ACTIVE
import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Unity, useUnityContext } from 'react-unity-webgl';
import { FullscreenOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// APIs
import { verifyUser } from '../apis/admin.api';
import { useGetUserDataQuery } from '../apis/rtkData.api';
import { fetchChapterDataApi } from '../apis/data.api';
import { reloadSession, updateCurrentUserTokens, deleteSession } from '../apis/user.api';

// Images
import Logo from '../images/lavenir-logo.png';
// REDUX
import {
  setCurrentPage,
  unloadUnityApp,
  setUnityAppRunning,
  setSentTokenMessage
} from '../redux/app.slice';
// UNITY APP SUPPORT SCRIPTS
// import recorder from '../scripts/recorder'
// PROCESSES
import { procTraineeUnityData } from '../processes/fetchUserDataRtkQ';
import { 
  resetUser,              // eslint-disable-line
  setUser,
  setName,
  setRole,
  setOrganization,
  setLoginStatus,
  setEmailVerified,
  setIdToken,
  setRrefreshCount
} from "../redux/user.slice"; // eslint-disable-line

// BROTLI VERSION !!
const UnityApp = () => {
  // console.log('UnityApp RENDERING');
  const dispatch = useDispatch();
  const iframeRef = useRef();
  const [pageInitialLoadded, setPageInitialLoadded] = useState(false);
  
  const [isPlaying, setIsPlaying] = useState(true);
  const [sentMessage, setSentMessage] = useState(false);
  const [isGameOver, setIsGameOver] = useState(false); // eslint-disable-line
  const [userName, setUserName] = useState(); // eslint-disable-line
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  
  const [chapters, setChapters] = useState([]);
  const [startDate, setStartDate] = useState(new Date('07/01/23'));
  const [endDate, setEndDate] = useState(new Date());
  const [userData, setUserData] = useState({});
  const [ userProgressDataReady, setUserProgressDataReady ] = useState(false);
  const [trainingProgData, setTrainingProgData] = useState(null);
  
  const [score, setScore] = useState(); // eslint-disable-line
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user); // eslint-disable-line
  const app = useSelector((state) => state.app); // eslint-disable-line
  const { refreshCount } = useSelector((state) => state.user); // eslint-disable-line
  
  const selection = {
    startDate,
    endDate
  };

  const {
    data: usrData,
    error: usrErr,
    isLoading: isLoadingUsrData
  } = useGetUserDataQuery({
    authToken: user.idToken,
    user: user,
    selection
  }); 

  // console.log(`process.env.REACT_APP_WEBGL_BUILD_ID: ${process.env.REACT_APP_WEBGL_BUILD_ID}:`, app)
  var intendedWidth = "1800";
  var intendedHeight = "790"; //800
  var aspectRatio = intendedWidth / intendedHeight;
  var targetWidth = window.innerWidth - 30;
  var targetHeight = targetWidth / aspectRatio;

  window.addEventListener("resize", resizeCanvas);

  const {
    unityProvider,
    isLoaded,
    loadingProgression,
    sendMessage,
    addEventListener, // eslint-disable-line
    removeEventListener, // eslint-disable-line
    requestFullscreen,
    takeScreenshot, // eslint-disable-line
    unload
  } = (process.env.REACT_APP_USE_BROTLI === 'false') ? useUnityContext({
    loaderUrl: `${process.env.REACT_APP_WEBGL_BUILD_ID}/Build/${process.env.REACT_APP_WEBGL_BUILD_ID}.loader.js`,
    dataUrl: `${process.env.REACT_APP_WEBGL_BUILD_ID}/Build/${process.env.REACT_APP_WEBGL_BUILD_ID}.data`,
    frameworkUrl: `${process.env.REACT_APP_WEBGL_BUILD_ID}/Build/${process.env.REACT_APP_WEBGL_BUILD_ID}.framework.js`,
    codeUrl: `${process.env.REACT_APP_WEBGL_BUILD_ID}/Build/${process.env.REACT_APP_WEBGL_BUILD_ID}.wasm`,
    matchWebGLToCanvasSize: true,
    companyName: 'LavenirAI',
    productName: 'LavenirAI',
    productVersion: '1b.0.2',
    webglContextAttributes: {
      preserveDrawingBuffer: true
    },
    // cacheControl: handleCacheControl,
  }) : 
  useUnityContext({
    loaderUrl: `${process.env.REACT_APP_WEBGL_BUILD_ID}/Build/${process.env.REACT_APP_WEBGL_BUILD_ID}.loader.js`,
    dataUrl: `${process.env.REACT_APP_WEBGL_BUILD_ID}/Build/${process.env.REACT_APP_WEBGL_BUILD_ID}.data.br`,
    frameworkUrl: `${process.env.REACT_APP_WEBGL_BUILD_ID}/Build/${process.env.REACT_APP_WEBGL_BUILD_ID}.framework.js.br`,
    codeUrl: `${process.env.REACT_APP_WEBGL_BUILD_ID}/Build/${process.env.REACT_APP_WEBGL_BUILD_ID}.wasm.br`,
    matchWebGLToCanvasSize: true,
    companyName: 'LavenirAI',
    productName: 'LavenirAI',
    productVersion: '1b.0.2',
    webglContextAttributes: {
      preserveDrawingBuffer: true
    },
    // cacheControl: handleCacheControl,
  });

  function handleCacheControl(url) {
    if (url.match(/\.framework/) || url.match(/\.loader/) || url.match(/\.wasm/) || url.match(/\.data/) || url.match(/\.bundle/)) {
      return "must-revalidate";
    }
    if (url.match(/\.mp4/) || url.match(/\.wav/)) {
      return "immutable";
    }
    return "no-store";
  }

  useEffect(() => {
    dispatch(setCurrentPage('unityApp'));
    dispatch(setSentTokenMessage(false));
    dispatch(unloadUnityApp(false));
    dispatch(setUnityAppRunning(true));
    setUserProgressDataReady(false);
  }, []);

  useEffect(() => {
    console.log('!!!! --> UnityApp refreshCount:', refreshCount)
    // if (loadingProgression >= 1 && isLoaded && userProgressDataReady && trainingProgData != null) {
    try {
      // console.log('!!!!!!!!!!!!!!!!!!!!!!')
      // console.log('UNITY APP UPDATING TOKEN')
      // console.log(user.idToken)
      // console.log('!!!!!!!!!!!!!!!!!!!!!!')
      sendMessage(
        'UserSessionContext',
        'ReceiveNewAuthToken',
        user.idToken
      );
    } catch (err) {
      console.log('sendMessage with token update failed:', err)
    }
    
  }, [refreshCount])

  useEffect(() => {
    // if (!isLoadingUsrData && usrData.err && usrData.err === "Invalid or expired token") {
    console.log('--- UnityApp usrData.error:', usrData, usrData && usrData.error && usrData.error.message)
    if (!isLoadingUsrData && usrData && usrData.error && usrData.error.message.includes("token")) {
      toast.error("Invalid or expired token, please logout and login again.");
      // handleUnload();
      setIsPlaying(false);
      dispatch(setCurrentPage('login'));
      dispatch(setSentTokenMessage(false));
      dispatch(unloadUnityApp(false));
      dispatch(setUnityAppRunning(false));
      setUserProgressDataReady(false);
      dispatch(setLoginStatus(false));
      dispatch(resetUser());
      deleteSession();
      navigate('/login', { replace: true });
    } else {
      if (!isLoadingUsrData) {
        fetchUserDataProcess();
      }
    }
  }, [isLoadingUsrData, usrData]); // eslint-disable-line
  useEffect(() => {
    if (Object.keys(userData).length > 0) {
      const result = buildDataForTrainingProgressTable();
      // adding delay 
      setTimeout(() => {
        setUserProgressDataReady(result);
      }, 1500);
    }
  }, [userData && userData.sessionCount]);

  const fetchUserDataProcess = async () => {
    const chapterData = await fetchChapterDataApi(user.idToken, user);
    const { userDataObj } = await procTraineeUnityData(usrData);
    setChapters(chapterData.data);
    setUserData(userDataObj);
  };

  const buildDataForTrainingProgressTable = async () => {
    // console.log('+++++> USER DATA:', userData)
    // userData.sessions ==> sessions with length, modules and a user
    const lessons = {};
    const quizzes = {};
    const negotiations = {};

    const tableData =
      await userData.sessions &&
      userData.sessions.map((s, index) => {
        // console.log("==========[> index, s:", index, s)
        return s.unitysessionmodules.map((m, i) => {  // eslint-disable-line
          // console.log('==========[> i, m:', i,  m)
          if (m.moduleType === 'lesson' && m.userScore !== -1) {
            let date = new Date(s.sessionStartTime).toISOString().split('T')[0];
            const dateArr = date.split('-');
            date = dateArr[1] + '-' + dateArr[2] + '-' + dateArr[0];

            if (!lessons[s.user.name]) lessons[s.user.name] = {};
            if (!lessons[s.user.name][m.moduleName]) { lessons[s.user.name][m.moduleName] = {}; }
            lessons[s.user.name][m.moduleName] = {
              // moduleType: m.moduleType,
              moduleType: 'Lesson',
              module: m.moduleName,
              user: s.user.name,
              userScore: m.userScore,
              complete: m.userScore === -1 ? 'X' : '\u2713',
              date
            };
          }
          if (m.moduleType === 'quiz' && m.userScore !== -1) {
            let date = new Date(s.sessionStartTime).toISOString().split('T')[0];
            const dateArr = date.split('-');
            date = dateArr[1] + '-' + dateArr[2] + '-' + dateArr[0];

            if (!quizzes[s.user.name]) quizzes[s.user.name] = {};
            if (!quizzes[s.user.name][m.moduleName]) { quizzes[s.user.name][m.moduleName] = {}; }
            quizzes[s.user.name][m.moduleName] = {
              // moduleType: m.moduleType,
              moduleType: 'Quiz',
              module: m.moduleName,
              user: s.user.name,
              userScore: m.userScore,
              complete: m.userScore === -1 ? 'X' : '\u2713',
              date
            };
          }
          // if (m.moduleType !== 'negotiation' || m.moduleType === 'negotiation') {
          if (m.moduleType === 'negotiation' && m.userScore !== -1) {
            let date = new Date(s.sessionStartTime).toISOString().split('T')[0];
            const dateArr = date.split('-');
            date = dateArr[1] + '-' + dateArr[2] + '-' + dateArr[0];

            if (!negotiations[s.user.name]) negotiations[s.user.name] = {};
            if (!negotiations[s.user.name][m.moduleName]) { negotiations[s.user.name][m.moduleName] = {}; }
            negotiations[s.user.name][m.moduleName] = {
              // moduleType: m.moduleType,
              moduleType: 'Negotiation',
              module: m.moduleName,
              user: s.user.name,
              userScore: m.userScore,
              complete: m.userScore === -1 ? 'X' : '\u2713',
              date
            };
          }
        });
      });
    // console.log('!!! ---> ===> tableData:', tableData)
    
    // console.log('==========> chapters:', chapters);
    let chapterLessons = [];
    let chapterQuizzes = [];
    let chapterNegotiations = [];
    let chapterLookup = {};
    chapters && chapters.forEach((c) => {
        c.lessons.forEach((l) => {
          chapterLookup[l.moduleName] = c.title;
          chapterLessons = [
            ...chapterLessons,
            {
              chapter: c.title,
              chapterId: c._id,
              chapterIndex: c.chapterIndex,
              number: l.number,
              moduleType: 'Lesson',
              module: l.title,
              userScore: -1,
              complete: 'X',
              date: ''
            }
          ];
        });
        c.quizzes.forEach((q) => {
          chapterLookup[q.moduleName] = c.title;
          chapterQuizzes = [
            ...chapterQuizzes,
            {
              chapter: c.title,
              chapterId: c._id,
              chapterIndex: c.chapterIndex,
              number: q.number,
              moduleType: 'Quiz',
              module: q.title,
              userScore: -1,
              complete: 'X',
              date: ''
            }
          ];
        });
        c.negotiations.forEach((n) => {
          // console.log('n:', n)
          chapterLookup[n.moduleName] = c.title;
          chapterNegotiations = [
            ...chapterNegotiations,
            {
              chapter: c.title,
              chapterId: c._id,
              chapterIndex: c.chapterIndex,
              number: n.number,
              moduleType: 'Negotiation',
              module: n.title,
              userScore: -1,
              complete: 'X',
              date: ''
            }
          ];
        });
      });
    // console.log('!!!!!! chapterLookup:', chapterLookup)
    const rebuiltTableData = [];
    const lessonKeys = Object.keys(lessons);
    lessonKeys.forEach((k) => {
      chapterLessons.forEach((l) => {
        l.user = k;
        if (!lessons[k][l.module]) {
          lessons[k][l.module] = { ...l };
        } else {
          lessons[k][l.module].chapter = l.chapter;
          lessons[k][l.module].number = l.number;
        }
        rebuiltTableData.push(lessons[k][l.module]);
      });
    });
    const quizKeys = Object.keys(quizzes);
    quizKeys.forEach((k) => {
      chapterQuizzes.forEach((q) => {
        q.user = k;
        if (!quizzes[k][q.module]) {
          quizzes[k][q.module] = { ...q };
        } else {
          quizzes[k][q.module].chapter = q.chapter;
          quizzes[k][q.module].number = q.number;
        }
        rebuiltTableData.push(quizzes[k][q.module]);
      });
    });
    const negotiationKeys = Object.keys(negotiations);
    negotiationKeys.forEach((k) => {
      chapterNegotiations.forEach((q) => {
        q.user = k;
        if (!negotiations[k][q.module]) {
          negotiations[k][q.module] = { ...q };
        } else {
          negotiations[k][q.module].chapter = q.chapter;
          negotiations[k][q.module].number = q.number;
        }
        rebuiltTableData.push(negotiations[k][q.module]);
      });
    });
    
    // console.log('===> rebuiltTableData:', rebuiltTableData);

    let concatenatedTableData = [];
    const lessonKeyArr = Object.keys(lessons);
    lessonKeyArr.forEach((k) => {
      if ( k === user.name) {
        const userLessonsKeyArr = Object.keys(lessons[k]);
        userLessonsKeyArr.forEach ((lk) => {
          if (lessons[k][lk] != null && lessons[k][lk].userScore > 0) {
            lessons[k][lk].chapter = chapterLookup[lessons[k][lk].module];
            concatenatedTableData = [...concatenatedTableData, lessons[k][lk]]
          }
        })  
      }
    })
    
    const quizKeyArr = Object.keys(quizzes);
    quizKeyArr.forEach((k) => {
      if ( k === user.name) {
        const userQuizzesKeyArr = Object.keys(quizzes[k]);
        userQuizzesKeyArr.forEach ((lk) => {
          if (quizzes[k][lk] != null && quizzes[k][lk].userScore > 0) {
            quizzes[k][lk].chapter = chapterLookup[quizzes[k][lk].module];
            concatenatedTableData = [...concatenatedTableData, quizzes[k][lk]]
          }
        })  
      }
    })
    // console.log('!!!!! ------------> quizzes concatenatedTableData:', concatenatedTableData)
    
    const negotiationKeyArr = Object.keys(negotiations);
    negotiationKeyArr.forEach((k) => {
      if ( k === user.name) {
        const userNegotaitionsKeyArr = Object.keys(negotiations[k]);
        userNegotaitionsKeyArr.forEach ((lk) => {
          if (negotiations[k][lk] != null && negotiations[k][lk].userScore > 0) {
            negotiations[k][lk].chapter = chapterLookup[negotiations[k][lk].module];
            concatenatedTableData = [...concatenatedTableData, negotiations[k][lk]]
          }
        }) 
      }
    })
    // console.log('!!!!! ------------> concatenatedTableData:', concatenatedTableData)
    const userProgData = concatenatedTableData.flat().filter(data => data.user === user.name); 
    await setTrainingProgData(userProgData);
    // console.log('!!!!! ------------> userProgData:', userProgData, userProgData.length)
    // return concatenatedTableData.flat();
    return true;
  
  };

  const filterNull = (tableData) => {
    return tableData.map((e) => {
      return e.map((m) => {
        if (m !== null && m !== undefined) {
          return m;
        }
        return [];
      });
    });
  };

  useEffect(() => {
    if (pageInitialLoadded && app.unloadUnityApp) {
      console.log('!!! UnityApp UNLOADING APP !!!')
      // toast('Unloaded the LavenirAI Negotiation App, navigation enabled.');
      handleUnload();
      dispatch(setUnityAppRunning(false));
      dispatch(setSentTokenMessage(false));
      // console.log('UnityApp ===> setSentTokenMessage(false)')
      setIsPlaying(false);
      setTimeout(() => {
        navigate('/');
      }, 50);
    }
  }, [pageInitialLoadded, app.unloadUnityApp]); // eslint-disable-line
  // console.log('-------------> {process.env.REACT_APP_WEBGL_BUILD_ID:', process.env.REACT_APP_WEBGL_BUILD_ID)
  

  function resizeCanvas() {
      var intendedWidth = "1800";
      var intendedHeight = "790";
      var aspectRatio = intendedWidth / intendedHeight;
      var targetWidth = window.innerWidth - 30;
      var targetHeight = targetWidth / aspectRatio;
      //.canvas.style.width = targetWidth + "px"; //"1680px"; //better, widescreen, but not ideal //"1805px"; //example of bad
      Unity.style.height = targetHeight + "px";//"720px"; //better, widescreen, but not ideal //"895px"; //example of bad
      Unity.style.width = targetWidth+ "px";//"720px"; //better, widescreen, but not ideal //"895px"; //example of bad
  }

  useEffect(() => {
    // console.log("======== loadingProgression =====> ",loadingProgression, isLoaded)
    // NOTE: userProgressDataReady is written after trainingProgData, but we were seeing the case where
    // it took long enough for trainingProgData to be written that userProgressDataReady would get 
    // updated first.  Added await to the update for trainingProgData AND added the null check below
    // for redundancy
    if (loadingProgression >= 1 && isLoaded && userProgressDataReady && trainingProgData != null) {
      setTimeout(() => {
        console.log('==============> LOADED SEND TOKEN!!!!')
        handleClickSendToken();
        setSentMessage(true);
        dispatch(setSentTokenMessage(true));
        setPageInitialLoadded(true);
        // console.log('UnityApp ===> setSentTokenMessage(true)')
      // }, 4000);
      }, 300);
    }
  }, [loadingProgression, isLoaded, userProgressDataReady, trainingProgData]); // eslint-disable-line

  async function handleUnload() {
    // MUST AWAIT DUE TO BUG IN Unity 2021.2 and LATER
    sendMessage('UserSessionContext', 'EndSession');
    setTimeout(async () => {
      await unload();
    }, 1000);
  }

  useEffect(() => {
    window.addEventListener('popstate', (e) => {
      // console.log('=================> BLOCKING BACK NAV <==================')
      navigate('/app', { replace: false });
    });
    return async () => {};
  }, []); // eslint-disable-line

  useEffect(() => {
    const verify = async () => {
      const verification = await verifyUser();
      // console.log("====> verification.data:", verification.data)
      await setUserLoggedIn(verification.data.loggedIn);
      if (!verification.data.loggedIn) {
        await window.history.pushState({}, '', '/login');
        // navigate('/login', { replace: true });
      }
    };
    verify();
    // TO LAUNCH APP IN A SEPERATE TAB
    // launch();
    // returnHome();
  }, []); // eslint-disable-line

  function handleClickSpawnEnemies() {
    sendMessage('JSHook', 'SendText', 'Some Mesage');
  }
  function handleClickSendToken() {
    let organizationId = typeof user.organization === 'string' ? user.organization : user.organization.name;

    let sendObj = {
      authtoken: user.idToken,
      userEmail: user.email,
      serverIp: process.env.REACT_APP_USE_BROTLI === 'false' ? "http://localhost:4040" : process.env.REACT_APP_API_SERVER_IP,
      organizationId,
      debugVerbosity: app.verbosity ? parseInt(app.verbosity) : 0,
      deployServer: process.env.REACT_APP_DEPLOY_SERVER,
    };

    if (process.env.REACT_APP_USE_BROTLI === 'false') {
      sendObj = {
        ...sendObj,
        nodeManIp: "http://localhost:4030",
        speechIp: "http://localhost:4050",
      };
    }
    
    app.reactVerbosity >= 2 && console.log('===TO UNITY====> sendObj:', sendObj);
    
    // Send initial user context
    sendMessage(
      'UserSessionContext',
      'ReceiveUserContext',
      JSON.stringify(sendObj)
    );

    // Send user progress data separately when it's ready
    if (userProgressDataReady && trainingProgData !== null) {
      const progressData = {
        userProgressData: trainingProgData
      };
      setTimeout(() => {
        sendMessage(
          'UserSessionContext',
          'ReceiveUserProgressData',
          JSON.stringify(progressData)
        );
      }, 0);
    }
  }

  const handleFullScreen = async () => {
    await requestFullscreen(true);
  };

  const handleClickOverlay = () => {
    // console.log('OVERLAY CLICK');
    if (sentMessage) {
      handleUnload();
      setIsPlaying(false);
      // toast('The LavenirAI application is now unloaded, navigation re-enabled.')
      navigate('/');
    }
  };

  return (    
    <div className="page-grid">
      <div className="unity-page-container">
        <div className="unity-page">
          <div className="unity-page-grid-1">
            {!isLoaded && (
              <div className="unity-page-loading-logo">
                <img src={Logo} style={{ width: '200px' }} alt="lavenirai" />
              </div>
            )}
            {!isLoaded && (
              <div className="unity-page-loading-progress">
                {Math.round(loadingProgression * 100)}%
              </div>
            )}
            <div
              id="unityContainer"
              className="unity-page-sect-1-1"
              ref={iframeRef}
            >

              <div className="unity-section-1-grid-1">
                
                {userLoggedIn && (
                  <Unity
                    className="unity-app-1"
                    id="unity"
                    unityProvider={unityProvider}
                    tabIndex="-1"
                    devicePixelRatio={window.devicePixelRatio}
                    style={{
                      display: isLoaded ? 'flex' : 'none',
                      visibility: isLoaded ? 'visible' : 'hidden',
                      // height: '110%',
                      // width: '110%'
                    }}
                  />
                )}
                
              </div>
              {isGameOver === true && (
                <p>{`Game Over ${userName}! You've scored ${score} points.`}</p>
              )}
            </div>

            <div className="unity-page-sect-2">
              <div id="unity-loading-bar">
                <div id="unity-logo"></div>
                <div id="unity-progress-bar-empty">
                  <div id="unity-progress-bar-full"></div>
                </div>
              </div>
            </div>

            <div id="unity-warning"> </div>

            <div className="unity-page-sect-4">
              {true && (
                <button
                  className="unity-app-unload-button"
                  onClick={handleUnload}
                >
                  Unload
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnityApp;
