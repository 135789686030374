import React, { useEffect, Suspense } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import WebFont from 'webfontloader';

// PAGEs
import Base from './pages/Base';
import Login from './pages/Login';
import LoginEntraID from './pages/Login_EntraID';
import Register from './pages/Register';
import ForgotPassword from './pages/ForgotPassword';
import AdminDash from './pages/AdminDash';
import LeaderDash from './pages/LeaderDash';
import TraineeDash from './pages/TraineeDash';
import UnityApp from './pages/UnityApp';
import Administrator from './pages/Administrator';
import LogEngine from './pages/LogEngine';
// COMPONENTs
import UserNav from './components/UserNav';
// REDUX
import { 
  setTriedToReload, 
  setPrivacy, 
  setAppVerbosity, 
  setAppReactVerbosity } from './redux/app.slice';
import { 
  resetUser,              // eslint-disable-line
  setUser,
  setName,
  setRole,
  setOrganization,
  setLoginStatus,
  setEmailVerified,
  setIdToken,
  setRrefreshCount
} from "./redux/user.slice"; // eslint-disable-line

import {
  OAuthProvider,
  GoogleAuthProvider,
  signOut,                    // eslint-disable-line
  getAuth,
  onAuthStateChanged,
  reauthenticateWithCredential,
  EmailAuthProvider
} from "firebase/auth";  

import { auth } from './config/fb';
// APIs
import { verifyUser, updateSession } from './apis/admin.api';
import { reloadSession, updateCurrentUserTokens, deleteSession } from './apis/user.api';
import { fetchAppVerbosityApi } from './apis/app.api';

if (navigator.appVersion.indexOf('Win') !== -1) {
  // Windows
  document.getElementsByTagName('body')[0].classList.add('win');
} else if (navigator.appVersion.indexOf('Mac') !== -1) {
  // Mac
  document.getElementsByTagName('body')[0].classList.add('mac');
}

function App() {
  const dispatch = useDispatch();
  const { triedToReload } = useSelector((state) => state.app, shallowEqual);
  const { currentPage } = useSelector((state) => state.app, shallowEqual);
  const { email } = useSelector((state) => state.user.user, shallowEqual);
  const { role } = useSelector((state) => state.user.user, shallowEqual);
  const { name } = useSelector((state) => state.user.user, shallowEqual);
  const { organization } = useSelector((state) => state.user.user, shallowEqual);
  const { loginStatus } = useSelector((state) => state.user, shallowEqual); // eslint-disable-line
  const { refreshCount } = useSelector((state) => state.user); // eslint-disable-line
  const app = useSelector((state) => state.app); // eslint-disable-line
  
  // const { user } = useSelector((state) => ({ ...state })); // eslint-disable-line
  const { user } = useSelector((state) => state.user); // eslint-disable-line


  // useEffect(() => {
  //   setInterval(async () => {
  //     // Testing cacheBusting
  //     console.log('** BUSTING THE CACHE **')
  //     window.caches.open('my-cache').then(function(cache) {
  //       cache.keys().then(function(keys) {
  //         keys.forEach(function(key) {
  //           cache.delete(key);
  //         });
  //       });
  //       console.log('** CACHE BUSTED **')
  //     });
  //   },  40 * 1000);
  // }, []);
  
  useEffect(() => {
    // if (user==null) {
    //   navigate("/login");
    // }
    return auth.onIdTokenChanged(async (user) => {
        if (!user) {
          app.reactVerbosity >= 2 && console.log('onIdTokenChanged --- NO USER')
            // setUser(null)
        }   
        else {
            const token = await user.getIdToken();
            // console.log("onIdTokenChanged ---- token", token);
            // console.log("onIdTokenChanged ---- refreshToken", user.refreshToken);
        }
    })
  }, []);

  useEffect(() => {
    setInterval(async () => {
      // check to see that accessToken has not expired

      const userVerification = await verifyUser();
      // console.log('---> userVerification now:', new Date())
      // console.log('--> userVerification.data.expiresIn:', userVerification.data.expiresIn)
      app.reactVerbosity >= 2 && console.log('-> userVerification.data:', userVerification.data)
      if (userVerification.data.cookie === 'expired') {
        app.reactVerbosity >= 2 && console.log('EXPIRED EXPIRED EXPIRED')
        await dispatch(setLoginStatus(false));
        await dispatch(setEmailVerified(false));
        await dispatch(resetUser());
        await deleteSession();
        await window.history.pushState({}, '', '/login');
      }
      // console.log('-> redux user:', user)
      // 5 represents the minutes left until the current authToken expires
      if (userVerification.data.expiresIn <= 5) {

        // let firebaseUser = await getAuth().currentUser;
        // console.log('-> fireBaseUser user:', firebaseUser)

        await  getAuth().currentUser.getIdToken(true).then(async function(idToken) {  // <------ Check this line
            // console.log('!!!-->> getIdToken:', idToken);
            const response = await updateSession(idToken); // eslint-disable-line
            app.reactVerbosity >= 2 && console.log('======> TOKEN REFRESHED RESPONSE:', response)   
            const dbUser = await updateCurrentUserTokens(idToken, email);
            // console.log('======> UPDATED dbUser:', dbUser) 
            await dispatch(setIdToken(idToken));
            await dispatch(setRrefreshCount());
        });
      
      }
      // console.log('INTERVAL TIMESTAMP:', new Date())
    }, 2 * 60 * 1000); // the setInterval interval 2*60*1000 => validateUser every 2min
  }, []);
  

  const checkToSeeIfSessionExpired = async () => {
    
  };

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Poppins', 'Montserrat']
      }
    });
  }, []);

  useEffect(() => {
    // this reloadSession version does not revert to teh AutoLogin page
    const reload = () => {
      setTimeout(async () => {
        // console.log('RELOAD SESSION', triedToReload)
        if (!triedToReload) {
          try {
            reloadSession().then(async (res) => {
              if (res.data.error) {
                app.reactVerbosity >= 2 && console.log('APP RELOAD SESSION ERROR:', res.data.error)
                await window.history.pushState({}, '', '/login');
              } else {
                console.log('APP RELOAD SESSION SUCCESS')
                await dispatch(setTriedToReload(true));
                if (res.data !== '') {
                  const user = res.data;
                  const idTokenResult = user.accessToken;
                  const verbosity = await fetchAppVerbosityApi(idTokenResult);
                  // console.log('!!! --------------> refresh user, app.verbosity:', user.email, verbosity.data);
                  await dispatch(setAppVerbosity(verbosity.data.verbosity));
                  await dispatch(setAppReactVerbosity(verbosity.data.reactVerbosity));
                  await dispatch(setIdToken(idTokenResult));
                  await dispatch(setUser(JSON.stringify(user)));
                  await dispatch(setLoginStatus(user.loggedIn));
                  await dispatch(setRole(user.role));
                  await dispatch(setName(user.name));
                  await dispatch(setOrganization(user.organization));
                  if (user && user.organization && Object.keys(user.organization).includes('privacy')) {
                    await dispatch(setPrivacy(user.organization.privacy));
                  } else {
                    await dispatch(setPrivacy(0));
                  }
                  await window.history.pushState({}, '', '/');
                }
              }
              
            });
          } catch (err) {
            // TBD remove log
            // console.log(err)
          }
        } else {
          // console.log("ALREADY TRIED TO RELOAD SESSION name:", name, " email:", email, " role:", role, " org:", organization, " loginStatus:", loginStatus)
        }
      }, 300);
    };
    reload();
  }, []); // eslint-disable-line

  return (
    <div style={{ overflow: 'hidden' }}>
      <ToastContainer />
      <Router>
        <div className="app-grid">
          <Header />
          <div className="app-core">
            <Suspense
              fallback={
                <div
                  style={{
                    fontSize: '36px',
                    display: 'flex',
                    margin: 0,
                    width: '100vw',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  Loading...
                </div>
              }
            >
            <Routes>
            
                <Route path="/login-entraid" element={<LoginEntraID />} />
                <Route path="/login" element={<Login />} />

                {loginStatus && role &&
                  <Route path="/" element={<Base />} />}
                
                {loginStatus && role === 'admin' &&
                <Route path="/admin/dash" element={<AdminDash />} />}
                {loginStatus && (role === 'leader' || role === 'admin') &&
                <Route path="/leader/dash" element={<LeaderDash />} />}
                {loginStatus && (role === 'trainee' || role === 'admin') &&
                <Route path="/trainee/dash" element={<TraineeDash />} />}
                {loginStatus &&
                <Route path="/app" element={<UnityApp />} />}
                {loginStatus && role === 'admin' &&
                <Route path="/administrator" element={<Administrator />} />}
                {loginStatus && role === 'admin' &&
                <Route path="/logengine" element={<LogEngine />} />}

                <Route path="/register" element={<Register />} />
                <Route path="/register/complete" element={<Login />} />
                <Route path="/forgot/password" element={<ForgotPassword />} />
                <Route path="*" element={<Base />} />

              </Routes>
            </Suspense>
          </div>

          {false && currentPage !== 'login' && currentPage !== 'register' && (
            <Footer />
          )}

        </div>
      </Router>
    </div>
  );
}

function Header() {
  // visible on every page
  return (
    <div className="app-header">
      <UserNav />
    </div>
  );
}
function Footer() {
  // const { app } = useSelector((state) => ({ ...state })); // eslint-disable-line
  // visible on every page
  return (
    <div className="app-footer">
      <p>
        LavenirAI -{' '}
        <a href="mailto:support@lavenirai.com">support@lavenirai.com</a>
      </p>
    </div>
  );
}

export default App;

// firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(function(idToken) {
        //   // Send token to your backend via HTTPS
        //   // ...
        // }).catch(function(error) {
        //   // Handle error
        // });


        // try {
        //   const userVerification = await verifyUser();
        //   // console.log('---> userVerification.data:', new Date())
        //   // console.log('--> userVerification.data.expiresIn, parseInt(userVerification.data.expiresIn) <= 5:', userVerification.data.expiresIn, parseInt(userVerification.data.expiresIn) <= 5)
        //   // console.log('-> userVerification.data:', userVerification.data)
        //   if (true) {
        //   // if (userVerification.data.cookie !== 'expired' && parseInt(userVerification.data.expiresIn) <= 5) {
        //     console.log('!!!! SESSION EXPIRED, USE REFRESH TOKEN !!!!')
        //     auth.currentUser
        //       .getIdToken(true)
        //       .then(async function (idToken) {
        //         console.log('checkToSeeIfSessionExpired ===========>> idToken:', idToken)
        //         const response = await updateSession(idToken); // eslint-disable-line
        //         console.log('======> TOKEN REFRESHED:', response)
        //         try {
        //           const dbUser = await updateCurrentUserTokens(idToken, email); // eslint-disable-line
        //         } catch (err) {
        //           // TBD remove log
        //           console.log('updateSession, reloadSesion err:', err)
        //         } // reloadSession
        //       })
        //       .catch(function (error) {  // eslint-disable-line
        //         console.log('===> REFRESH TOKEN ERROR:', error)
        //       });
        //   }
        //   // either undefined or
        //   // if it has, update Redux and DB (DB from the API server upon detection)
        // } catch (err) {
        //   // if errors out, assume expiration and update Redux and DB (DB from the API server upon detection)
        //   console.log('!!!!!!userVerification ERROR:', err)
        // }