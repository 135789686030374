/* React-specific entry point that automatically generates
   hooks corresponding to the defined endpoints */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// const BASE_URL = "localhost";
//    const SERVER_PORT = "4040";
// baseUrl: `http://${BASE_URL}:${SERVER_PORT}`,

const SERVER_PORT = process.env.REACT_APP_SERVER_PORT; // 4010;

let REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL_LOC;
if (process.env.REACT_APP_MODE === 'docker') {
  REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL_DOC;
}
let baseUrl = `http://${REACT_APP_BASE_URL}`;
if (process.env.REACT_APP_DEPLOY === 'nginx') {
  baseUrl += '/api';
} else if (process.env.REACT_APP_DEPLOY === 'local') {
  baseUrl += `:${SERVER_PORT}/api`;
}

// console.log('!! --==>> baseUrl:', baseUrl)

const dataApi = createApi({
  reducerPath: 'data',
  baseQuery: fetchBaseQuery({
    // baseUrl: `http://${REACT_APP_BASE_URL}:${SERVER_PORT}`,
    baseUrl
  }),
  endpoints(builder) {
    return {
      getChapters: builder.query({
        // invalidatesTags: ['Score'],      // STATIC
        // invalidatesTags: (result, error, id) => {
        //     // this can be a set of type and can be conditional based on result
        //     return [{type: 'Data', id }]
        // },

        providesTags: (result, error) => {
          // console.log('===> CHAPTERS QUERY ==> ');
          // return ['Chapters']
          return [{ type: 'Chapters' }];
        }, // DYNAMIC
        query: ({ authToken, user, selection }) => {
          // console.log('CHAPTERS QUERY being CALLED!!: ', user);
          return {
            url: '/get/chapters',
            method: 'POST',
            body: { user, selection },
            header: authToken
          };
        },
        transformResponse: (response) => {
          // console.log('===> CHAPTERS QUERY RESPONSE ==> ', response);
          return response;
        }
      }),
      getUserData: builder.query({
        providesTags: (result, error, id) => {
          // console.log('===> DATA QUERY RESULT ==> ', result);
          return ['Data'];
        }, // DYNAMIC
        query: ({ authToken, user, selection }) => {
          // console.log('DATA QUERY being CALLED!!', authToken, user, selection);
          return {
            url: '/unity/user/data',
            method: 'POST',
            body: { user, selection },
            header: authToken
          };
        },
        transformResponse: (response) => {
          // console.log('===> DATA QUERY RESPONSE ==> ', response);
          return response;
        }
      })
      // updateSelection: builder.mutation(
      //     console.log('!!!!!!!! MUTATION !!!!!!!!'),
      //     {

      //     query: (body) => ({
      //       url: 'post',
      //       method: 'POST',
      //       body,
      //     }),
      //     invalidatesTags: ['Data'],
      //   }),
    };
  }
});

export const { useGetChaptersQuery, useGetUserDataQuery } = dataApi;
export { dataApi };
