import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DateSelectionLE = ({ handleStartDate, handleEndDate, setDefaultDates, handleUseSelection, useDateSelection, setUseDateSelection }) => {
  // console.log('DateSelection RENDERING');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    // startDate.setDate(startDate.getDate() - 60);
    const today = new Date();
    let newStart = new Date(today.getFullYear(), today.getMonth(), 1)
    console.log('---> newStart:', newStart)
    setStartDate(newStart);
    setDefaultDates(newStart, today);
    // setStartDate(new Date('09/01/23'));
    // setDefaultDates(startDate, endDate);
  }, []);

  const handleStart = async (date) => {
    setStartDate(date);
    await handleStartDate(date);
  };
  const handleEnd = async (date) => {
    setEndDate(date);
    await handleEndDate(date);
  };
  const handleAll = async () => {
    const date = new Date('Janurury 1, 2023 00:00:00');
    await setStartDate(date);
    await handleStartDate(date);
  };

  return (
    <div className="">
      <div className="date-time-component">
        <div className="date-time-grid">

        {!useDateSelection && <div className='date-selector-check-le'> 
          <p>Use Date Selector</p>
          <input className='logengine-page-ai-checkbox' type="checkbox" onChange={handleUseSelection} />
        </div>}
        
        {useDateSelection && 
        <div className='date-selector-le'>      
          <div className="start-date-time-title-le">Data Selector:</div>
          <div className="start-date-time-label-le">Start</div>
          <div className="start-date-time-le">
            <DatePicker
              className="datePicker"
              selected={startDate}
              onChange={(date) => handleStart(date)}
            />
          </div>
          <div className="end-date-time-label-le">End</div>
          <div className="end-date-time-le">
            <DatePicker
              className="datePicker"
              selected={endDate}
              onChange={(date) => handleEnd(date)}
            />
          </div>
          <button className="all-time-button-le" onClick={handleAll}>
            All
          </button>
          </div> }

        </div>
      </div>
    </div>
  );
};

export default React.memo(DateSelectionLE);
