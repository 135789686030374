import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  region: 'North America',
  azone: 'us-west-2',
  mode: 'prod',
  triedToReload: false,
  unityAppRunning: false,
  currentPage: 'login',
  sentTokenMessage: false,
  unloadUnityApp: false,
  verbosity: 0,
  text: '',
  reactVerbosity: 0,
  privacy: 0,
  reset: true,
};

export const appSlice = createSlice({
  name: 'updateAppState',
  initialState,
  reducers: {
    setPrivacy: (state, action) => {
      // console.log('---------------> setPrivacy:', action.payload);
      state.privacy = action.payload;
    },
    setAppVerbosity: (state, action) => {
      // console.log('---- app.slice setAppVerbosity:', action)
      state.verbosity = action.payload;
    },
    setAppReactVerbosity: (state, action) => {
      // console.log('---- app.slice setAppReactVerbosity:', action)
      state.reactVerbosity = action.payload;
    },
    unloadUnityApp: (state, action) => {
      state.unloadUnityApp = action.payload;
    },
    setSentTokenMessage: (state, action) => {
      state.sentTokenMessage = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setUnityAppRunning: (state, action) => {
      state.unityAppRunning = action.payload;
    },
    setRegion: (state, action) => {
      state.region = action.payload;
    },
    setAZone: (state, action) => {
      state.azone = action.payload;
    },
    setMode: (state, action) => {
      state.mode = action.payload;
    },
    setReset: (state, action) => {
      state.reset = action.payload;
    },
    setTriedToReload: (state, action) => {
      // console.log('action:', action)
      state.triedToReload = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  setAppVerbosity,
  setAppReactVerbosity,
  unloadUnityApp,
  setSentTokenMessage,
  setContext,
  setMode,
  setReset,
  setTriedToReload,
  setUnityAppRunning,
  setCurrentPage,
  setPrivacy
} = appSlice.actions;
// You must export the reducer as follows for it to be able to be read by the store.
export default appSlice.reducer;
