import axios from 'axios';

export const fetchAppVerbosityApi = async (authtoken) => {
  // console.log('===>fetchAppVerbosityApi authtoken:', authtoken);
  const axiosInstance = axios.create({
    withCredentials: true
  });
  return await axiosInstance.get(process.env.REACT_APP_API + '/get/verbosity', {
    headers: {
      authtoken
    }
  });
};

export const updateApplicationApi = async (authtoken, application) => {
  // console.log('--- updateApplicationApi:', application);
  const axiosInstance = axios.create({
    withCredentials: true
  });
  const config = {
    headers: {
      authtoken
    }
  };
  return await axiosInstance.post(
    process.env.REACT_APP_API + '/app/update',
    { application },
    config
  );
};

export const updateUnityAppVerbosityApi = async (authtoken, verbosity) => {
  // console.log('api call updateUnityAppVerbosityApi... ');
  const axiosInstance = axios.create({
    withCredentials: true
  });
  const config = {
    headers: {
      authtoken
    }
  };
  return await axiosInstance.post(
    process.env.REACT_APP_API + '/app/update/unity-app-verbosity',
    { verbosity },
    config
  );
};

export const updateUnityAppLoginStatusApi = async (application) => {
  // console.log('---- updateUnityAppLoginStatusApi:', application);
  const axiosInstance = axios.create({
    withCredentials: true
  });
  return await axiosInstance.post(
    process.env.REACT_APP_API + '/app/update/login-status',
    { application }
  );
};

