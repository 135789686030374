import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DateSelection = ({ handleStartDate, handleEndDate }) => {
  // console.log('DateSelection RENDERING');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    // startDate.setDate(startDate.getDate() - 60);
    setStartDate(new Date('09/01/23'));
    // setDefaultDates(startDate, endDate);
  }, []);

  const handleStart = async (date) => {
    setStartDate(date);
    await handleStartDate(date);
  };
  const handleEnd = async (date) => {
    setEndDate(date);
    await handleEndDate(date);
  };
  const handleAll = async () => {
    const date = new Date('Janurury 1, 2023 00:00:00');
    await setStartDate(date);
    await handleStartDate(date);
  };

  return (
    <div className="">
      <div className="date-time-component">
        <div className="date-time-grid">
          <div className="start-date-time-title">Data Selector:</div>
          <div className="start-date-time-label">Start Date</div>
          <div className="start-date-time">
            <DatePicker
              className="datePicker"
              selected={startDate}
              onChange={(date) => handleStart(date)}
            />
          </div>
          <div className="end-date-time-label">End Date</div>
          <div className="end-date-time">
            <DatePicker
              className="datePicker"
              selected={endDate}
              onChange={(date) => handleEnd(date)}
            />
          </div>
          <button className="all-time-button" onClick={handleAll}>
            All
          </button>
        </div>
      </div>
    </div>
  );
};

export default React.memo(DateSelection);
