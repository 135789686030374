import React, { useMemo } from 'react';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';

const Table1 = ({ data, maxModuleCount, reactVerbosity }) => {
  // console.log('Table1 RENDERING');
  // data.forEach((s, i) => {
  //   const countStr = `${s.sessionCount}/${s.sessions}`
  //   data[i].sessionCount = countStr;
  // });
  // console.log('====> maxModuleCount:', maxModuleCount)

  // console.log('###################> TABLE1 DATA PRE:', data);
  
  // data.forEach((s, i) => {
  //   const countStr = `${s.sessionCount}/${s.sessionCountOrg}`;
  //   data[i].sessionCount = countStr;
  //   const moduleCountStr = `${s.modules}/${maxModuleCount}`;
  //   data[i].modules = moduleCountStr;
  // });
  reactVerbosity === 2 &&
    console.log('###################> TABLE1 DATA:', data);
  // console.log('###################> TABLE1 DATA AFTER:', data);

  const columns = useMemo(() => [
    {
      accessorKey: 'name', // access nested data with dot notation
      header: 'Name'
    },
    {
      accessorKey: 'modules',
      header: 'Modules'
    },
    {
      accessorKey: 'sessionCount', // normal accessorKey
      header: 'Sessions (Trainee/Org)'
    },
    {
      accessorKey: 'negotiationTime',
      header: 'Negotiation (min)'
    },
    {
      accessorKey: 'negotiations',
      header: 'Negotiations'
    }
  ]);

  const table = useMantineReactTable({
    columns,
    data,
    enableColumnResizing: true,
    enableGrouping: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    initialState: {
      density: 'xs',
      expanded: true,
      // grouping: ['negotiation'],
      pagination: { pageIndex: 0, pageSize: 20 },
      // sorting: [{ id: 'negotiation', desc: false }],
      mantineTableContainerProps: { sx: { height: '80%' } }
    }
  });

  return <MantineReactTable table={table} />;
};

export default React.memo(Table1);
