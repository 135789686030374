import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { sendPasswordResetEmail } from 'firebase/auth';

// FB
import { auth } from '../config/fb';
// Images
import Logo from '../images/lavenir-logo.png';

const ForgotPassword = () => {
  // console.log('ForgotPassword RENDERING');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    await sendPasswordResetEmail(auth, email)
      .then(function () {
        setEmail('');
        setLoading(false);
        toast.success('Check your email for the password reset link', {
          className: 'toast-message'
        });
        navigate('/login');
      })
      .catch(function (err) {
        console.log('RESET PWD EMAIL ERROR:', err);
        setLoading(false);
        toast.error(err.message);
      });
  };

  const resetForm = () => {
    return (
      <form className="forgot-password-page-form" onSubmit={handleSubmit}>
        <input
          type="email"
          className="form-control-email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="enter your email"
          autoComplete="off"
          autoFocus
        />
        <div style={{margin: "10px 0 10px 0"}}></div>
        <Link
          className="reset-password-button"
          style={{ display: !isValidEmailAddress() ? "none" : "flex" }}
          onClick={handleSubmit}
          disabled={!isValidEmailAddress()}
        >
          Send Password Reset Email
        </Link>
      </form>
    );
  };
  const isValidEmailAddress = () => {
    return !!email.match(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/    // eslint-disable-line
    ); // eslint-disable-line
  };
  return (
    <div className="login-background">
      <div className="login-page">
        <div className="login-page-grid">
          <div className="forgot-password-card" style={{ height: errorMessage ? "340px" : "240px" }}>
            
            <div className="login-page-welcome">
              {!errorMessage ? loading
                ? (<div className="text-warning">Loading..</div>)
                : (<div className="forgot-card-welcome">Reset your password</div>) 
                : <div className="text-error">Error</div> }
            </div>
            
            <div className="login-page-form">{resetForm()}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
