// force deploy
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { Button } from 'antd';

import MS_ICON from '../data/icons8-microsoft-48.png';

// FB
import {
  signInWithEmailAndPassword,
  signInWithPopup,
} from 'firebase/auth';
import { auth, googleAuthProvider, msAuthProvider } from '../config/fb';
// REDUX
import { setReset, setCurrentPage, setPrivacy, setAppVerbosity } from '../redux/app.slice';
/* eslint-disable */
import {
  setUser,
  setName,
  setOrganization,
  setRole,
  setLoginStatus,
  setIdToken
} from "../redux/user.slice"; 
/* eslint-enable */
// APIs
import { setCurrentUser, reloadSession } from '../apis/user.api';
import { fetchAppVerbosityApi, updateApplicationApi } from '../apis/app.api';
import { writeLoginErrorToS3Api, writeLoginSuccessToS3Api } from '../apis/logs.api';

const LoginEntraID = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [ email, setEmail ] = useState('');
  const [ loading, setLoading ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState(null);
  const { reset } = useSelector((state) => state.app, shallowEqual);
  // console.log('Login EntraID RENDERING', reset);
  

  useEffect(() => {
    dispatch(setCurrentPage('login-entraid'));
  }, []);

  useEffect(() => {
    setEmail('');
    setErrorMessage(null);
    dispatch(setReset(false));
    setLoading(false);
  }, [reset])

  const msEntraIDLogin = async => {
    
    try {
      signInWithPopup(auth, msAuthProvider)
        .then(async (userCredential) => {
          // console.log('---------> MS PROVIDER');
          let user = userCredential.user;
          const idTokenResult = await user.getIdTokenResult();
          user.idToken = idTokenResult.token;
          // console.log('--------> idTokenResult.token:', idTokenResult.token);
          const dbUser = await setCurrentUser(
            idTokenResult.token,
            JSON.stringify(user),
            user.email,
            userCredential
          );

          if (Object.keys(dbUser.data).includes("error")) {
            // console.log('MS NO dbUser!', dbUser)

            if (dbUser.data.error === "User not found") {
              // HERE MEANS USER FOUND in FB but NOT in DB
              try {
                // User does not exist in this region. Try logging into your assigned region or contact support@lavenirai.com. Regions are app.lavenirai.com, app-eu.lavenirai.com, app-sg.lavenirai.com errorCode=L5
                // const message = "User does not exist in this region. Try logging in in you home region or contact support@lavenirai.com errorCode=L5"
                const message = "User does not exist in this region. Try logging into your assigned region or contact support@lavenirai.com. Regions are app.lavenirai.com, app-eu.lavenirai.com, app-sg.lavenirai.com errorCode=mL5";
                setErrorMessage(message)
                writeLoginErrorToS3Api({
                  email,
                  error: {
                    message,
                    resErr: dbUser.data,
                  }
                })
              } catch (err) {
                // console.log("writeLoginErrorToS3Api failure:", err)
              }
              return null;
            }
          } else {
            // console.log('MS LOGIN -> dbUser:', dbUser);
            await loadUserApp(idTokenResult);
            await loadSession(idTokenResult, user);
            navigate('/');
          }
        })
        .catch((error) => {
          try {
            const message = "User not registered, contact support@lavenirai.com errorCode=mL2";       
            setErrorMessage(message);
            writeLoginErrorToS3Api({
              email,
              error: {
                message,
                resErr: error,
              }
            })
          } catch (err) {
            // console.log("writeLoginErrorToS3Api failure:", err)
          }
          navigate('/login-entraid');
        });
    } catch (err) {
      try {
        const message = "User not registered, contact support@lavenirai.com errorCode=mL1";
        setErrorMessage(message);
        writeLoginErrorToS3Api({
          email,
          error: {
            message,
            resErr: err,
          }
        })
      } catch (err) {
        // console.log("writeLoginErrorToS3Api failure:", err)
      }
      setLoading(false);
    }
  }

  const loadUserApp = async (idTokenResult) => {
    const verbosity = await fetchAppVerbosityApi(idTokenResult.token);
    
    if (verbosity) {
      if (!Number.isInteger(verbosity.data)) verbosity.data = 0;
      console.log('LOGIN signInWithEmailAndPassword app.verbosity:', verbosity.data)
      await dispatch(setAppVerbosity(verbosity.data));
    } else {
      console.log('---- error loadUserApp:', verbosity)
    }
  }
  const loadSession = async (idTokenResult, user) => {
    console.log('--- loadSession')
    try {
      await reloadSession().then(async (res) => {
        user = res.data;
        if (res.data.error) {
          console.log('\n\nLOGIN RELOAD SESSON ERROR (376):', res.data.error, '\n\n');
          try {
            const message = "Something failed during login, please contact support@lavenirai.com errorCode=L4"
            setErrorMessage(message);
            writeLoginErrorToS3Api({
              email,
              error: {
                message,
                resErr: res.data.error,
              }
            })
          } catch (err) {
            console.log("writeLoginErrorToS3Api failure (388):", err)
          }
          return null;
        } else {
          console.log('LOGIN user:', user)
          await dispatch(setIdToken(idTokenResult.token));
          await dispatch(setUser(JSON.stringify(user)));
          await dispatch(setLoginStatus(true));
          await dispatch(setName(user.name));
          await dispatch(setRole(user.role));
          await dispatch(setOrganization(user.organization.name));
          await dispatch(setPrivacy(user.organization.privacy));
          const application = {
            userId: user._id,
            userEmail: user.email,
            organizationId: user.organization._id,
            organizationName: user.organization.name,
            webUiRevision: process.env.REACT_APP_WEBUI_VERSION,
            unityAppRevision: process.env.REACT_APP_WEBGL_BUILD_ID,
            mode: process.env.REACT_APP_MODE,
            loginStatus: true,
            lastLoggedIn: new Date(),
            region: process.env.REACT_APP_REGION_CODE,
            deployServer: process.env.REACT_APP_DEPLOY_SERVER,
          };
          console.log('LOGIN application:', application)
          await updateApplicationApi(idTokenResult.token, application);
          if (process.env.REACT_APP_SUCCESS_LOGGING === "true") {
            try {
              writeLoginSuccessToS3Api(idTokenResult.token, {email});
            } catch (err) {
              console.log("writeLoginSuccessToS3Api failure (416):", err)
            }
          }
        }
      });
    } catch (err) {
      // HERE MEANS USER FOUND in FB and in DB but could NOT RELOAD SESSION or UPDATE APP
      try {
        const message = "Something failed during registration, please contact contact support@lavenirai.com errorCode=L3";
        setErrorMessage(message);
        writeLoginErrorToS3Api({
          email,
          error: {
            message,
            resErr: err,
          }
        })
      } catch (err) {
        console.log("writeLoginErrorToS3Api failure (434):", err)
      }
      return null;
    }
  }

  const loginFormEntraID = () => {
    return (
      <div className="login-page-form">
        
        <div style={{margin: "100px 0 10px 0"}}></div>
        
        <Button
          className="google-login-button"
          // icon={<GoogleOutlined />}
          icon={<img style={{ width: '32px' }} src={MS_ICON} />}
          onClick={msEntraIDLogin}
        >Log in with EntraID</Button>

        <div style={{margin: "10px 0 10px 0"}}></div>
       {errorMessage && 
        <div className="login-page-error-wrapper">
          <div className="login-page-error">{errorMessage}</div>
        </div>}
      </div>
    );
  };

  return (
    <div className="login-background">
      <div className="login-page">
        <div className="login-page-grid">
          <div className="login-page-card" style={{ height: errorMessage ? "410px" : "410px" }}>
            
            <div className="login-page-welcome">
              {!errorMessage ? loading
                ? (
                <div className="text-warning">Loading..</div>
                  )
                : (
                <div className="">Login to your account</div>
                  ) : <div className="text-error">Error</div> }
            </div>
            <div className="" style={{ minHeight: '450px' }}>
              {loginFormEntraID()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginEntraID;