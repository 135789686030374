// import * as firebase from 'firebase'
import { initializeApp } from 'firebase/app';
import {
  OAuthProvider,
  GoogleAuthProvider,
  signOut,                    // eslint-disable-line
  getAuth,
  onAuthStateChanged,
  reauthenticateWithCredential,
  EmailAuthProvider
} from "firebase/auth";       // eslint-disable-line
// import * as admin from 'firebase-admin'

const firebaseConfig_A = {
  apiKey: process.env.REACT_APP_FB_KEY_A,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN_A,
  projectId: process.env.REACT_APP_PROJECT_ID_A,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET_A,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID_A,
  appId: process.env.REACT_APP_ID_A
};

const app = initializeApp(firebaseConfig_A); // eslint-disable-line
const auth = getAuth();
const googleAuthProvider = new GoogleAuthProvider();
const msAuthProvider = new OAuthProvider('microsoft.com');
msAuthProvider.setCustomParameters({
  // Force re-consent.
//  prompt: 'consent',
  // Target specific email with login hint.
  login_hint: 'user@firstadd.onmicrosoft.com'
});

// TBD: potential options
// googleAuthProvider.addScope('https://www.googleapis.com/auth/contacts.readonly');
// googleAuthProvider.setCustomParameters({
//   'login_hint': 'user@example.com'
// });
export { auth, googleAuthProvider, msAuthProvider };

onAuthStateChanged(auth, (user) => {
  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/firebase.User
    // const uid = user.uid;
    // console.log("====> User is signed in", uid);
  } else {
    // User is signed out
    // console.log("========> User is signed out");
  }
});

export const fbProto = async (auth, uid) => {
  const user = getAuth().currentUser;
  const credentials = EmailAuthProvider.credential(user.email, '123456');
  reauthenticateWithCredential(credentials);
};
