import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// COMPONENTS
import Total from '../components/Total';
import LeftComboGraph from '../components/LeftComboGraph';
import RightComboGraph from '../components/RightComboGraph';
import DateSelection from '../components/DateSelection';
import Table1 from '../components/Table1';
import Table2 from '../components/Table2b';
import Table3 from '../components/Table3';
import NegNameTraineeScoreTable from '../components/NegNameTraineeScoreTable';
import TrainingProgTable from '../components/TrainingProgTable';
// API
// import { fetchChapterDataApi } from '../apis/data.api';
import { getUnityModuleCount, deleteSession } from '../apis/user.api';
import { verifyUser, fetchOrganizationsApi } from '../apis/admin.api';
// PROC
import { procAdminUnityData } from '../processes/fetchUserDataRtkQ';
// REDUX
import {
  setCurrentPage,
  setUnityAppRunning,
  setSentTokenMessage
} from '../redux/app.slice';
import { useGetChaptersQuery, useGetUserDataQuery } from '../apis/rtkData.api';
import { getChapterGroupApi } from '../apis/data.api';
import { 
  resetUser,              // eslint-disable-line
  setLoginStatus
} from "../redux/user.slice"; // eslint-disable-line

const AdminDash = () => {
  // console.log('AdminDash RENDERING');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userData, setUserData] = useState({});
  const [userLoggedIn, setUserLoggedIn] = useState(false); // eslint-disable-line
  const [organizationArray, setOrganizationArray] = useState([]);
  const [organizationOptions, setOrganizationOptions] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState('');
  const [startDate, setStartDate] = useState(new Date('09/01/23'));
  const [endDate, setEndDate] = useState(new Date());
  const [table1Data, setTable1Data] = useState([]);
  const [table3Data, setTable3Data] = useState([]);
  const [trainingProgData, setTrainingProgData] = useState([]);
  const [negTableData, setNegTableData] = useState([]);
  const [topNegTableData, setTopNegTableData] = useState([]);
  const [duration, setDuration] = useState(0);
  const [maxModuleCount, setMaxModuleCount] = useState(0);
  const [chapters, setChapters] = useState([]);
  const [ chapterGroup, setChapterGroup ] = useState({});
  const [moduleCount, setModuleCount] = useState(0);
  const [negotiationCount, setNegotiationCount] = useState(0);
  const [traineeNames, setTraineeNames] = useState([]);
  const [negotiationTime, setNegotiationTime] = useState(0);
  const [negotiationTitles, setNegotiationTitles] = useState([]); // eslint-disable-line
  const [negotiationLengths, setNegotiationLengths] = useState([]); // eslint-disable-line
  const { loginStatus } = useSelector((state) => state.user, shallowEqual); // eslint-disable-line
  const { reactVerbosity } = useSelector((state) => state.app); // eslint-disable-line
  const { privacy } = useSelector((state) => state.app, shallowEqual); // eslint-disable-line
  const { user } = useSelector((state) => state.user, shallowEqual); // eslint-disable-line

  const selection = {
    startDate,
    endDate
  };
  /* eslint-disable */
  // console.log('!!!!!!! ===> adminDash user.idToken:', user.idToken)
  const authToken = user.idToken;
  const {
    data: chData,
    error,
    isLoading: isLoadingChData,
    isSuccess,
    isError,
    isFetching
  } = useGetChaptersQuery({
    authToken,
    user,
    selection
  }); // eslint-disable-line
  const {
    data: usrData,
    error: usrErr,
    isLoading: isLoadingUsrData
  } = useGetUserDataQuery({
    authToken: user.idToken,
    user,
    selection
  }); 
   /* eslint-enable */

  useEffect(() => {
    reactVerbosity === 2 && console.log('CHCHCH=========> rtkq chData:', reactVerbosity, chData);
    setChapters(chData);
  }, [chData]);

  useEffect(() => {
    getChapterGroup();
  }, [user]);

  const getChapterGroup = async () => {
    const chGrp = await getChapterGroupApi(user.idToken, 'default');
    setChapterGroup(chGrp);
  }

  const fetchOrganizations = async () => {
    try {
      const orgRes = await fetchOrganizationsApi(user.idToken);
      // console.log('---> orgRes:', orgRes)
      let orgResOptions = [];
      orgRes.data.forEach((o, i) => {
        orgResOptions.push({
          key: i, value: o._id, label: o.name
        })
      })
      // console.log('========> orgArr:', orgResOptions);
      setOrganizationArray(orgRes.data);
      setOrganizationOptions(orgResOptions);
      setSelectedOrg(orgResOptions[0]);
    } catch (err) {
      console.log('----x fetchOrganizations:', err);
    }
  };

  useEffect(() => {
    // console.log('+++++> USER DATA:', userData)
  }, [startDate, endDate, usrData && usrData.length]);

  useEffect(() => {
    reactVerbosity === 2 &&
      console.log(
        'USR DATA LENGTH UPDATED!!!!!!!!!!:',
        usrData && usrData.length
      );
  }, [usrData]);

  useEffect(() => {
    dispatch(setCurrentPage('adminDash'));
    fetchOrganizations();
    // console.log('AdminDash user:', user);
  }, []);

  useEffect(() => {
    window.addEventListener('popstate', (e) => {
      navigate('/', { replace: false });
    });
    return async () => {};
  }, []); // eslint-disable-line

  useEffect(() => {
    const verify = async () => {
      let startTime = 0;
      reactVerbosity === 3 && (startTime = performance.now());

      const verification = await verifyUser();

      let endTime = 0;
      reactVerbosity === 3 && (endTime = performance.now());
      reactVerbosity === 3 &&
        console.log(
          `ttttttttttttt ---->> AdminDash verifyUser took ${
            endTime - startTime
          } milliseconds`
        );
      // console.log("====> verification.data:", verification.data)
      if (
        loginStatus === false ||
        !verification.data.loggedIn ||
        verification.data.role !== 'admin'
      ) {
        await deleteSession();
        navigate('/login', { replace: true });
      } else {
        await setUserLoggedIn(true);
        // await handleStartDate(new Date('07/01/23'))
      }
    };
    verify();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (Object.keys(userData).length > 0) {
      reactVerbosity === 2 &&
        console.log('useEffect updating Tables +++++> USER DATA:', userData);
      buildDataForTable1();
      buildDataForTable3();
      buildDataForNegTable();
      buildDataForTopNegTable();
      buildDataForTrainingProgressTable();
    }
    // console.log('DATES:', startDate, endDate);
  }, [userData.sessionCount]); // eslint-disable-line

  // res.status(200).json({
  //   "data": null,
  //   "error": {
  //     "isError": "1",
  //     "message": "Invalid or expired token",
  //     "code": "001"
  //   }
  // });
  useEffect(() => {
    reactVerbosity >= 2 && console.log('AdminDash userData:', usrData)
    // if (!isLoadingUsrData && usrData.err && usrData.err === "Invalid or expired token") {
      console.log('--- UnityApp usrData.error:', usrData, usrData && usrData.error && usrData.error.message)
    if (!isLoadingUsrData && usrData && usrData.error && usrData.error.message.includes("token")) {
    // if (!isLoadingUsrData && usrData.error && usrData.error.message === "Invalid or expired token") {
      toast.error("Invalid or expired token, please refresh or login again.");
      dispatch(setCurrentPage('login'));
      dispatch(setSentTokenMessage(false));
      dispatch(setUnityAppRunning(false));
      dispatch(setLoginStatus(false));
      dispatch(resetUser());
      deleteSession();
      navigate('/login', { replace: true });
    } else {
      if (!isLoadingUsrData) {
        fetchUserDataProcess();
      }
    }
  }, [isLoadingUsrData, usrData, selectedOrg]); // eslint-disable-line

  const fetchUserDataProcess = async () => {
    let startTime = 0;
    reactVerbosity === 3 && (startTime = performance.now());

    const maxModuleCount = await getUnityModuleCount();

    let endTime = 0;
    reactVerbosity === 3 && (endTime = performance.now());
    // console.log(`ttttttttttttt ---->> AdminDash getUnityModuleCount took ${endTime - startTime} milliseconds`)

    setMaxModuleCount(maxModuleCount.data);

    // FILTER usrData based on selectedOrg
    // console.log('----> PRE filteredInputData: ', usrData)
    
    let filteredInputData = usrData;
    if (selectedOrg !== null) {
      filteredInputData = usrData.map((d) => {
        if (d.organizationId === selectedOrg.label || d.organization.name === selectedOrg.label ) {
          return d;
        }
        return null;
      })
      filteredInputData = filteredInputData.filter(element => element !== null);
    }
    
    // console.log('----> selectedOrg.label, filteredInputData: ', selectedOrg.label, filteredInputData)
    
    const { userDataObj } = await procAdminUnityData(filteredInputData, moduleCount);
    reactVerbosity === 3 && (endTime = performance.now());
    reactVerbosity === 3 &&
      console.log(
        `ttttttttttttt ---->> AdminDash procAdminUnityData took ${
          endTime - startTime
        } milliseconds`
      );

    reactVerbosity === 2 &&
      console.log(
        'fetchUserDataProcess =======> maxModuleCount, fetchAdminUnityData:',
        maxModuleCount.data,
        userDataObj
      );
    setDuration(userDataObj.duration);
    setNegotiationCount(userDataObj.negotiations);
    setTraineeNames(userDataObj.names);
    setNegotiationTime(userDataObj.time);
    setNegotiationTitles(userDataObj.titles);
    setNegotiationLengths(userDataObj.lengths);
    setModuleCount(userDataObj.modules);
    setUserData(userDataObj);

    reactVerbosity === 3 && (endTime = performance.now());
    reactVerbosity === 3 &&
      console.log(
        `ttttttttttttt ---->> AdminDash fetchUserDataProcess took ${
          endTime - startTime
        } milliseconds`
      );
  };

  const buildDataForTrainingProgressTable = async () => {
    // userData.sessions ==> sessions with length, modules and a user
    const lessons = {};
    const quizzes = {};
    const selfAssessments = {};

    const tableData =  // eslint-disable-line
      userData.sessions &&
      userData.sessions.map((s, index) => {
      // console.log("==========[> index, s:", index, s)
        return s.unitysessionmodules.map((m, i) => { // eslint-disable-line
          // console.log('==========[> i, m:', i,  m)
          if (m.moduleType === 'lesson' && m.userScore !== -1 && m.userScore !== 0) {
            let date = new Date(s.sessionStartTime).toISOString().split('T')[0];
            const dateArr = date.split('-');
            date = dateArr[1] + '-' + dateArr[2] + '-' + dateArr[0];
            if (!lessons[s.user.name]) lessons[s.user.name] = {};
            if (!lessons[s.user.name][m.moduleName]) { lessons[s.user.name][m.moduleName] = {}; }
            lessons[s.user.name][m.moduleName] = {
              moduleType:'Lesson',
              module: m.moduleName,
              user: s.user.name,
              userScore: m.userScore,
              complete: m.userScore === -1 ? 'X' : '\u2713',
              date
            };
          }

          if (m.moduleType === 'quiz' && m.userScore !== -1 && m.userScore !== 0) {
            let date = new Date(s.sessionStartTime).toISOString().split('T')[0];
            const dateArr = date.split('-');
            date = dateArr[1] + '-' + dateArr[2] + '-' + dateArr[0];

            if (!quizzes[s.user.name]) quizzes[s.user.name] = {};
            if (!quizzes[s.user.name][m.moduleName]) { quizzes[s.user.name][m.moduleName] = {}; }
            quizzes[s.user.name][m.moduleName] = {
              moduleType: 'Quiz',
              module: m.moduleName,
              user: s.user.name,
              userScore: m.userScore,
              complete: m.userScore === -1 ? 'X' : '\u2713',
              date
            };
          }
          
          if (m.moduleType === 'selfassessment' && m.userScore !== -1 && m.userScore !== 0) {
            let date = new Date(s.sessionStartTime).toISOString().split('T')[0];
            const dateArr = date.split('-');
            date = dateArr[1] + '-' + dateArr[2] + '-' + dateArr[0];
            if (!selfAssessments[s.user.name]) selfAssessments[s.user.name] = {};
            if (!selfAssessments[s.user.name][m.moduleName]) { selfAssessments[s.user.name][m.moduleName] = {}; }
            selfAssessments[s.user.name][m.moduleName] = {
              moduleType: 'Self Assessment',
              module: m.moduleName,
              user: s.user.name,
              userScore: m.userScore,
              complete: m.userScore === -1 ? 'X' : '\u2713',
              date
            };
            // console.log('--> buildDataForTrainingProgressTable selfAssessments:', selfAssessments)
          }

          if (Object.keys(lessons).length === 0) {
            lessons[s.user.name] = {};
          }
          if (Object.keys(quizzes).length === 0) {
            quizzes[s.user.name] = {};
          }
          if (Object.keys(selfAssessments).length === 0) {
            selfAssessments[s.user.name] = {};
          }
          
          if (m.moduleType !== 'negotiation') {
            let date = new Date(s.sessionStartTime).toISOString().split('T')[0];
            const dateArr = date.split('-');
            date = dateArr[1] + '-' + dateArr[2] + '-' + dateArr[0];
            // console.log("===> m:", m)
            return {
              moduleType: m.moduleType,
              module: m.moduleName,
              user: s.user.name,
              userScore: m.userScore,
              complete: m.userScore === -1 ? 'X' : '\u2713',
              date
            };
          }
        });
      });

    // console.log('==========> chapters:', chapters)
    let chapterLessons = [];
    let chapterQuizzes = [];
    let chapterSelfAssessments = [];
    chapters && chapters.forEach((c) => {
        c.lessons.forEach((l) => {
          // console.log('title, moduleName:', l.title, l.moduleName)
          chapterLessons = [
            ...chapterLessons,
            {
              chapter: c.title,
              chapterId: c._id,
              chapterIndex: c.chapterIndex,
              number: l.moduleIndex,
              moduleType: 'Lesson',
              module: l.moduleName,
              userScore: -1,
              complete: 'X',
              date: ''
            }
          ];
        });
        c.quizzes.forEach((q) => {
          // console.log('====> q:', q, chapterQuizzes)
          chapterQuizzes = [
            ...chapterQuizzes,
            {
              chapter: c.title,
              chapterIndex: c.chapterIndex,
              chapterId: c._id,
              number: q.moduleIndex,
              moduleType: 'Quiz',
              module: q.moduleName,
              userScore: -1,
              complete: 'X',
              date: ''
            }
          ];
        });

        c.selfassessments && c.selfassessments.forEach((sa) => {
          // console.log('====> sa:', sa, chapterSelfAssessments)
          chapterSelfAssessments = [
            ...chapterSelfAssessments,
            {
              chapter: c.title,
              chapterIndex: c.chapterIndex,
              chapterId: c._id,
              number: parseInt(sa.moduleIndex),
              moduleType: 'Self Assessment',
              module: sa.moduleName,
              userScore: -1,
              complete: 'X',
              date: ''
            }
          ];
        });

    });

    const rebuiltTableData = [];
    const lessonKeys = Object.keys(lessons);
    lessonKeys.forEach((k) => {
      chapterLessons.forEach((l) => {
        l.user = k;
        if (!lessons[k][l.module]) {
          lessons[k][l.module] = { ...l };
        } else {
          lessons[k][l.module].chapter = l.chapter;
          lessons[k][l.module].number = l.moduleIndex;
        }
        rebuiltTableData.push(lessons[k][l.module]);
      });
    });
    const quizKeys = Object.keys(quizzes);
    quizKeys.forEach((k) => {
      chapterQuizzes.forEach((q) => {
        q.user = k;
        if (!quizzes[k][q.module]) {
          quizzes[k][q.module] = { ...q };
        } else {
          quizzes[k][q.module].chapter = q.chapter;
          quizzes[k][q.module].number = q.moduleIndex;
        }
        rebuiltTableData.push(quizzes[k][q.module]);
      });
    });

    const selfAssessmentKeys = Object.keys(selfAssessments);
    selfAssessmentKeys.forEach((k) => {
      chapterSelfAssessments.forEach((sa) => {
        sa.user = k;
        // console.log("====> sa.user, sa (438):", sa.user, sa)
        // console.log("======> selfAssessments[k] (439):", selfAssessments[k])
        if (!selfAssessments[k][sa.module]) {
          selfAssessments[k][sa.module] = { ...sa };
        } else {
          selfAssessments[k][sa.module].chapter = sa.chapter;
          selfAssessments[k][sa.module].number = sa.moduleIndex;
        }
        rebuiltTableData.push(selfAssessments[k][sa.module]);
      });
    });

    // console.log('=======> lessons:', lessons)
    // console.log('===> chapterLessons:', chapterLessons)
    // console.log('=======> quizzes:', quizzes)
    // console.log('=======> selfAssessments:', selfAssessments)
    // console.log('===> chapterQuizzes:', chapterQuizzes)
    // console.log('===> rebuiltTableData:', rebuiltTableData)

    let chapterGroupObj = {};
    rebuiltTableData.forEach((m) => {
      // if (m.chapterId) {
        if (!chapterGroupObj[m.chapterIndex]) chapterGroupObj[m.chapterIndex] = [];
        chapterGroupObj[m.chapterIndex] = [...chapterGroupObj[m.chapterIndex], m];
      // }
    })
    const cgKeys = Object.keys(chapterGroupObj);
    let concatenatedTableData = [];
    cgKeys.map((k) => {
      return concatenatedTableData = [...concatenatedTableData, chapterGroupObj[k]]
    })

    // console.log("===> concatenatedTableData.flat():", concatenatedTableData.flat())
    setTrainingProgData(concatenatedTableData.flat());
    return concatenatedTableData.flat();

  };

  const buildDataForTable1 = async () => {
    // console.log('+++++> USER DATA:', userData)
    const names = Object.keys(userData.moduleCountPerName);
    let maxSessions = 0;
    names.forEach((n) => {
      // console.log('userData.sessionCountObj[n]:', n, userData.sessionCountObj[n], parseInt(userData.sessionCountObj[n]))
      maxSessions = maxSessions + parseInt(userData.sessionCountObj[n]);
      // console.log('maxSessions:', maxSessions)
    })

    const tableData = names.map((n, id) => {
      let negotiations = 0;
      if (userData.negotiationsPerUser[n]) {
        negotiations = userData.negotiationsPerUser[n].length;
      }
      // console.log('Processing table1Data for:', n)
      return {
        name: n,
        modules: `${userData.moduleCountPerName[n]}/${maxModuleCount}`,
        negotiations,
        negotiationTime: Math.round(userData.lengthsObj[n]) || 0,
        sessionCount: `${userData.sessionCountObj[n]}/${maxSessions}`,
      }
    })
    // console.log('======> Table1 tableData:', tableData)
    await setTable1Data(tableData);
    return tableData;
  };

  const buildDataForTable3 = async () => {
    // console.log('buildDataForTable3 +++++> USER DATA:', userData)
    const names = Object.keys(userData.moduleCountPerName);
    let maxSessions = 0;
    names.forEach((n) => {
      // console.log('userData.sessionCountObj[n]:', n, userData.sessionCountObj[n], parseInt(userData.sessionCountObj[n]))
      maxSessions = maxSessions + parseInt(userData.sessionCountObj[n]);
      // console.log('maxSessions:', maxSessions)
    })

    const tableData = names.map((n, id) => {
      let negotiations = 0;
      if (userData.negotiationsPerUser[n]) {
        negotiations = userData.negotiationsPerUser[n].length;
      }
      // console.log('Processing table3Data for:', n)
      // console.log('userData.moduleCountPerName:', userData.moduleCountPerName)
      // console.log('userData.moduleCountPerName[n]:', userData.moduleCountPerName[n])
      return {
        name: n,
        // organization: s.organization.name,
        modules: `${userData.moduleCountPerName[n]}/${maxModuleCount}`,
        // modules: `${userData.uniqueModuleNamesPerUser[n].length}/${maxModuleCount}`,
        sessions: userData.sessionsPerName[n].length,
        negotiations,
        negotiationTime: Math.round(userData.lengthsObj[n]) || 0,
        sessionCount: `${userData.sessionCountObj[n]}/${maxSessions}`,
        // sessionCountOrg: userData.sessionCountPerOrgObj[s.organization.name]
      }
    })
    // console.log('======> tableData3:', tableData)
    await setTable3Data(tableData);
    return tableData;
  };


  const buildDataForNegTable = async () => {
    // console.log('+++++> USER DATA:', userData)
    const tableData =
      userData.sessions &&
      userData.sessions.map((s, index) => {
        // console.log("==========[> index, s:", index, s)
        return s.unitysessionmodules.map((m, i) => { // eslint-disable-line
          // console.log('=====> buildDataForNegTable: m:', m, m.userScore)
          if (
            m.moduleType === 'negotiation' &&
            m.userScore > 0 &&
            m.endTime - m.startTime > 0
          ) {
            // console.log('==========[> i, m:', i,  m)
            // console.log('xxxxxxxx >> m.userScore:', m.userScore)
            // console.log('xxxxxxxx >> m.maxScore:', m.maxScore)
            let date = new Date(s.sessionStartTime).toISOString().split('T')[0];
            const dateArr = date.split('-');
            date = dateArr[1] + '-' + dateArr[2] + '-' + dateArr[0];
            let returnObj = {
              negotiation: m.moduleName,
              user: s.user.name,
              userScore: m.userScore,
              maxScore: m.maxScore,
              date
            }
            // console.log('!!!!!! returnObj:', returnObj)
            return returnObj;
          }
        }
        );
      });
    // console.log('(573) ===> buildDataForNegTable tableData DATA:', tableData)
    const arrFiltered = await filterNull(tableData);
    const flattened = arrFiltered.flat();
    const flattenedFiltered = flattened.filter((e) => !Array.isArray(e));
    setNegTableData(flattenedFiltered);
    // console.log('=============> (578) buildDataForNegTable flattenedFiltered DATA:', flattenedFiltered)
    return flattenedFiltered;
  };

  const buildDataForTopNegTable = async () => {
    // console.log(' buildDataForTopNegTable +++++> USER DATA:', userData)
    // TOP NEGOTIATIONS
    const validModuleTypes = Object.keys(userData.totalModulesByType);
    const tableData =
      userData.sessions &&
      userData.sessions.map((s, index) => {
        return s.unitysessionmodules.map((m, i) => {
          if (
            m.moduleType === 'negotiation' &&
            m.userScore > 0 &&
            s.sessionLength > 0 &&
            validModuleTypes.includes(m.moduleName)
          ) {
            // console.log('==========[> i, m:', i,  m, m.moduleName, userData.totalModulesByType)
            // console.log("=========> moduleScores:", moduleScores)
            let date = new Date(s.sessionStartTime).toISOString().split('T')[0];
            const dateArr = date.split('-');
            date = dateArr[1] + '-' + dateArr[2] + '-' + dateArr[0];
            const moduleCountByType = userData.totalModulesByType[m.moduleName]
              ? userData.totalModulesByType[m.moduleName].length
              : 0;
            // console.log('ADMIN TopNegTable moduleCountByType:', moduleCountByType)
            const maxUserScore = Math.max(
              ...userData.moduleScoreObj[m.moduleName]
            );
            return {
              negotiation: m.moduleName,
              user: s.user.name,
              userScore: maxUserScore,
              maxScore: m.maxScore,
              moduleCountByType,
              date
            };
          } else return null;
        });
      });
    const arrFiltered = await filterNull(tableData);
    const flattened = arrFiltered.flat();
    const flattenedFiltered = flattened.filter((e) => !Array.isArray(e));
    const uniqueModules = Array.from(
      new Set(flattenedFiltered.map((a) => a.negotiation))
    ).map((negotiation) => {
      return flattenedFiltered.find((a) => a.negotiation === negotiation);
    });
    setTopNegTableData(uniqueModules);
    // console.log('buildDataForTopNegTable uniqueModules DATA:', uniqueModules)
    return uniqueModules;
  };

  const filterNull = (tableData) => {
    return tableData.map((e) => {
      return e.map((m) => {
        if (m !== null && m !== undefined) {
          return m;
        }
        return [];
      });
    });
  };

  const setDefaultDates = async (start, end) => {
    // start.setHours(start.getHours() + 4);
    await setStartDate(start);
    await setEndDate(end);
  };
  const handleStartDate = (date) => {
    // date.setHours(date.getHours());
    // date.setHours(0);
    setStartDate(date);
  };
  const handleEndDate = (date) => {
    setEndDate(date);
  };

  const handleSelectUserOrg = (selection) => {
    // console.log("handleSelectUserOrg:", selection)
    // console.log("organizationArray:", organizationArray)
    setSelectedOrg(selection);
    const selectedOrgObj = organizationArray.find((o) => o.name === selection.label);
    // console.log("selectedOrgObj:", selectedOrgObj)
    // setUserOrg(selectedOrgObj.name);
  }

  const customStyles = {
    // container: (provided) => ({
    //   ...provided,
    //   marginRight: "2.5rem",
    //   ":focus": {
    //       ...provided[":focus"],
    //       outline: "none",
    //   },
    //   "@media only screen and (max-width: 1200px)": {
    //       ...provided["@media only screen and (max-width: 1200px)"],
    //       marginRight: "1.5rem",
    //   },
    // }),
    option: provided => ({
      ...provided,
      color: 'black'
    }),
    control: (provided, state) => ({
      ...provided,
      color: 'black',
      width: '180px',
      borderColor: state.isFocused ? 'black' : 'hsla(194, 100%, 59%, 1)',
      "@media only screen and (max-width: 850px)": {
        ...provided["@media only screen and (max-width: 1200px)"],
        width: "150px",
        marginLeft: "30px",
      },
      "@media only screen and (max-width: 790px)": {
        ...provided["@media only screen and (max-width: 1200px)"],
        width: "110px",
        marginLeft: "30px",
      },
      "@media only screen and (max-width: 650px)": {
        ...provided["@media only screen and (max-width: 1200px)"],
        width: "90px",
        marginLeft: "50px",
      },
    }),
    singleValue: provided => ({
      ...provided,
      color: 'black'
    })
  }

  return (
    <div className="page-grid">
      <div className="admin-page-container">
        <div className="admin-page">
          <div className="admin-page-grid">
            <div className="admin-page-date-range-selector">
              {
                <DateSelection
                  start={startDate}
                  end={endDate}
                  setDefaultDates={setDefaultDates}
                  handleStartDate={handleStartDate}
                  handleEndDate={handleEndDate}
                />
              }
              
            </div>
            <div className='orgSelect'>
                <Select maxMenuHeight={200} 
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: 'hotpink',
                    primary: 'white',
                  },
                })}
                value={selectedOrg} 
                options={organizationOptions} 
                onChange={(option) => handleSelectUserOrg(option)} 
                // styles={{ 
                //   control: (baseStyles, state) => ({
                //     ...baseStyles,
                //     borderColor: state.isFocused ? 'grey' : 'red'
                //   }),
                //   menuPortal: base => ({ ...base, zIndex: 9999 }) 
                //   }} 
                styles={customStyles}
                />  
            </div>
            <div className="admin-page-sect-2">
              <div className="admin-page-total-sessions">
                {
                  <Total
                    label="Sessions"
                    value={userData.sessionCount}
                    width={70}
                    height={70}
                    borderRadius={30}
                  />
                }
              </div>
              <div className="admin-page-total-time">
                {
                  <Total
                    label="Time"
                    units="min"
                    value={duration}
                    width={70}
                    height={70}
                    borderRadius={30}
                  />
                }
              </div>
              <div className="admin-page-total-negotiations">
                {
                  <Total
                    label="Negotiations"
                    units=""
                    value={negotiationCount}
                    width={70}
                    height={70}
                    borderRadius={30}
                  />
                }
              </div>
              <div className="admin-page-total-negotiation-time">
                {
                  <Total
                    label=" Negotiation"
                    units="min"
                    value={(negotiationTime / 1000 / 60).toFixed(0)}
                    width={70}
                    height={70}
                    borderRadius={30}
                  />
                }
              </div>
              {false && (
                <div className="admin-page-active-users">
                  {
                    <Total
                      label="Active Users"
                      units=""
                      value={30}
                      width={70}
                      height={70}
                      borderRadius={30}
                    />
                  }
                </div>
              )}
            </div>
            <div className="admin-page-sect-3">
              {userData.sessionsPerDate && (
                <div className="admin-page-graph-1">
                  {
                    <LeftComboGraph
                      sessionsPerDate={[userData.sessionsPerDate]}
                      sessionTimePerDate={userData.sessionTimePerDate}
                      text={['Sessions vs. Date', 'Session Time vs. Date']}
                      reactVerbosity={reactVerbosity}
                    />
                  }
                </div>
              )}
              {userData.negotiationTimePerDate && (
                <div className="admin-page-graph-2">
                  <RightComboGraph
                    negotiationTimePerDate={userData.negotiationTimePerDate}
                    reactVerbosity={reactVerbosity}
                  />
                </div>
              )}
            </div>
            <div className="admin-page-sect-5">
              {true && (
                <div className="admin-page-table-1-grid">
                  <div className="admin-page-table-1-header">
                    <div className="admin-page-table1-label"> Organizations </div>
                  </div>
                  {true && (
                    <div className="admin-page-table-1">
                      <Table3
                        data={table3Data}
                        reactVerbosity={reactVerbosity}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
            {false && (
              <div className="admin-page-sect-6">
                {false && (
                  <Total
                    label="Label"
                    value={''}
                    width={100}
                    borderRadius={30}
                  />
                )}
              </div>
            )}
            <div className="admin-page-sect-7">
              {true && (
                <div className="admin-page-table-1-grid">
                  <div className="admin-page-table-1-header">
                    <div className="admin-page-table1-label">
                      Negotiation Scores
                    </div>
                  </div>
                  {
                    <div className="leader-page-table-1">
                      <NegNameTraineeScoreTable
                        data={negTableData}
                        reactVerbosity={reactVerbosity}
                      />
                    </div>
                  }
                </div>
              )}
            </div>
            <div className="admin-page-sect-8">
              <div className="leader-page-table-1-grid">
                <div className="admin-page-table-1-header">
                  <div className="admin-page-table1-label">Trainee Analytics</div>
                </div>
                {table1Data.length > 0 && (
                  <div className="leader-page-table-1">
                    <Table1
                      data={table1Data}
                      maxModuleCount={maxModuleCount}
                      reactVerbosity={reactVerbosity}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="admin-page-sect-9">
              {true && (
                <div className="trainee-page-table-1-grid">
                  <div className="admin-page-table-1-header">
                    <div className="admin-page-table1-label">
                      Top Negotiations
                    </div>
                  </div>
                  {topNegTableData.length > 0 && (
                    <div className="trainee-page-table-1">
                      <Table2
                        data={topNegTableData}
                        reactVerbosity={reactVerbosity}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="admin-page-sect-10">
              <div className="trainee-page-progress-grid">
                <div className="trainee-page-progress-header">
                  <div className="trainee-page-progress-label">
                    Training Progress
                  </div>
                </div>
                <div className="trainee-page-progress-table">
                  {
                    <TrainingProgTable
                      tableData={trainingProgData}
                      reactVerbosity={reactVerbosity}
                    />
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDash;
