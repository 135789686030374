import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

// import 'normalize.css';

import './styles/index.css';
import './styles/app.css';
import './styles/header.css';
// import './styles/dataPages.css';
import './styles/login.css';
import './styles/register.css';
import './styles/dateTime.css';
import './styles/totals.css';
import './styles/select.css';
import './styles/leaderPage.css';
import './styles/adminPage.css';
import './styles/traineePage.css';
import './styles/homePage.css';
import './styles/unityApp.css';
import './styles/administratorPage.css';
import './styles/documentPage.css';
import './styles/logEnginePage.css';
import App from './App';
import store from './redux/store';

// console.log('store:', store)
store.subscribe(() => {
  // console.log('store:', JSON.stringify(store.getState()))
});
// console.log('store:', store.getState())

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
