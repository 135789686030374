import React from 'react';

const Total = ({ value, units, label, width, height, borderRadius }) => {
  // console.log('Total RENDERING');
  const w = width + '%';
  const h = height + '%';
  return (
    <div className="total" style={{ width: w, height: h }}>
      <div>{label}</div>
      <div>
        {value} {units && <>({units})</>}
      </div>
    </div>
  );
};

export default React.memo(Total);
