import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button } from 'antd';
import { EnterOutlined } from '@ant-design/icons';
// FB
import { auth } from '../config/fb';
import {
  createUserWithEmailAndPassword,
  sendEmailVerification
} from 'firebase/auth';
// APIs
import { createUser } from '../apis/user.api';
import { checkUserCacheApi, fetchOrganizationId } from '../apis/admin.api';
import { writeRegistrationErrorToS3Api, writeRegistrationSuccessToS3Api } from '../apis/logs.api';
// REDUX
import { setReset, setCurrentPage } from '../redux/app.slice';

const Register = () => {
  // console.log('Register RENDERING');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [ valid, setValid ] = useState(false);
  const [organization, setOrganization] = useState('');
  const [queuedUser, setQueuedUser] = useState(null); // eslint-disable-line
  const [errorMessage, setErrorMessage] = useState(null);
  const { reset } = useSelector((state) => state.app, shallowEqual);
  
  useEffect(() => {
    dispatch(setCurrentPage('register'));
  }, []);

  useEffect(() => {
    const validEmail = isValidEmailAddress();
    const validPassword = isValidPassword();
      console.log('valid?', validEmail, validPassword, validEmail && validPassword)
      setValid(validEmail && validPassword);
  }, [email, password]);

  useEffect(() => {
    setEmail('');
    setPassword('');
    setErrorMessage(null);
    dispatch(setReset(false));
  }, [reset])

  const checkIfUserIsInQueue = async () => {
    const cacheRes = await checkUserCacheApi(email.toLowerCase());
    setQueuedUser(cacheRes.data);
    return cacheRes.data;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // HAS USER BEEN ADDED TO THE QUEUED USERS COLLECTION?
    let authorized = null;
    try {
      authorized = await checkIfUserIsInQueue();
    } catch (err) {
      // console.log('\n\n REQUEST TO DB QUEUED USERS FAILED:', err, '\n\n');
      try {
        const message = "This email is not authorized, please contact support@lavenirai.com errorCode=R4"
        setErrorMessage(message)
        writeRegistrationErrorToS3Api({
          email,
          error: {
            message,
            resErr: err,
          }
        })
      } catch (err) {
        // console.log("writeRegistrationErrorToS3Api failure:", err)
      }
    }
    
    if (authorized && (authorized.email.toLowerCase() === email.toLowerCase())) {
    // if (authorized !== undefined && authorized !== null && (authorized.email === email || authorized.email.toLowerCase() === email || authorized.email === email.toLowerCase())) {
      
      // CREATE FIREBASE USER
      createUserWithEmailAndPassword(auth, email, password)
        .then(async (res) => {
          // console.log('------> res.user:', res.user)
          const idTokenResult = await res.user.getIdTokenResult();
          // get user Organization _id
          const orgRes = await fetchOrganizationId(authorized.organization);
          console.log('------> createUser authorized.organization, orgRes:', orgRes )
          console.log('------> createUser authorized.organization:', authorized.organization)
          res.user.organization = authorized.organization;
          res.user.emailVerified = true;
          res.user.name = authorized.name;
          // console.log('========XXXXX==========> res.user:', res.user);

          // CREATE DB USER
          await createUser(idTokenResult, res.user, orgRes.data)
            .then((res) => {
              if (process.env.REACT_APP_SUCCESS_LOGGING === "true") {
                try {
                  writeRegistrationSuccessToS3Api(idTokenResult, {email});
                } catch (err) {
                  // console.log("writeRegistrationSuccessToS3Api failure:", err)
                }
              }
              // console.log('\n\n----> createUser SUCCESS ----->', res.data, '\n\n');
              navigate('/login');

            })
            .catch((err) => {
              try {
                const message = "Create user failed, please contact support@lavenirai.com errorCode=R3";
                setErrorMessage(message)
                writeRegistrationErrorToS3Api({
                  email,
                  error: {
                    message,
                    resErr: err,
                  }
                })
              } catch (err) {
                // console.log("writeRegistrationErrorToS3Api failure:", err)
              }
            });
          setEmail('');
          setPassword('');
          setOrganization('');
        })
        .catch((err) => {
          // IF createUserWithEmailAndPassword FAILS
          // console.log(typeof err, Object.keys(err), err.code, err.name, err);
          if (err.code === "auth/email-already-in-use") {
            try {
              const message = "This user is already registered, please try loging in or contact support@lavenirai.com errorCode=R2"
              setErrorMessage(message)
              writeRegistrationErrorToS3Api({
                email,
                error: {
                  message,
                  resErr: err,
                }
              })
            } catch (err) {
              // console.log("writeRegistrationErrorToS3Api failure:", err)
            }
            // IF USER IS LOGGING IN FROM EU DOES USER EXIST OUTSIDE EU?
            // IF USER IS LOGGING IN OUTSIDE EU DOES USER EXIST IN EU?
            // TODO: WHAT ACTION TO TAKE?
          }
        });
    } else {
      // IF USER NOT IN QUEUE
      try {
        const message = "This email is not authorized, please contact support@lavenirai.com or your team lead errorCode=R1"
        setErrorMessage(message)
        writeRegistrationErrorToS3Api({
          email,
          error: {
            message,
            resErr: null,
          }
        })
      } catch (err) {
        // console.log("writeRegistrationErrorToS3Api failure:", err)
      }
    }
  };

  const registerForm = () => {
    return (
      <form className="register-page-form" onSubmit={handleSubmit}>
        <input
          type="email"
          className="form-control-email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="enter your email"
          autoFocus
        />
        <div style={{margin: "10px 0 10px 0"}}></div>
        <input
          type="password"
          className="form-control-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="enter your password"
        />
        <div style={{margin: "10px 0 10px 0"}}></div>
        {false && (
          <input
            type="text"
            className="form-control"
            value={organization}
            onChange={(e) => setOrganization(e.target.value)}
            placeholder="enter your organization"
          />
        )}
        <div style={{margin: "10px 0 10px 0"}}></div>
        <Link
          className="register-button"
          onClick={handleSubmit}
          style={!valid ? {pointerEvents: 'none', backgroundColor: 'gray'}: {}}
        > Register </Link>

        {false && <Button
          icon={<EnterOutlined />}
          block
          shape="round"
          size="large"
          className="login-button"
          onClick={handleSubmit}
          disabled={!isValidEmailAddress()}
        >
          Submit
        </Button>}
        <div style={{margin: "10px 0 10px 0"}}></div>
       {errorMessage && 
        <div className="login-page-error-wrapper">
          <div className="login-page-error">{errorMessage}</div>
        </div>}
        {!valid && password.length > 2 &&
        <div className="register-page-pwd-instruction-wrapper" style={{ backgroundColor: 'blue ! important'  }}>
          <div className="register-page-pwd-instruction">Password requirements: 1 Upper Case, 1 lower case, 1 number, a minimum of 6 characters and can contain @$!%*?& </div>
        </div>}
      </form>
    );
  };
  const isValidEmailAddress = () => {
    return !!email.match(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ // eslint-disable-line
    ); // eslint-disable-line
  };
  const isValidPassword = () => {
    console.log('isValidPassword, password.length:', password.length)
    if (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z0-9@$!%*?&]{6,}$/.test(password)) {
      console.log('---> TRUE', password)
      return true;
    }
    return false;
  }

  return (
    <div className="login-background">
      <div className="register-page">
        <div className="register-page-grid">
          <div className="register-page-card" style={{ height: errorMessage || !valid ? "390px" : "330px" }}>

            <div className="reister-page-welcome">
              Register for an account
            </div>
            <div className="register-page-item-2">{registerForm()}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
